<script>
import PopupOrderVue from '../ui/PopupOrder.vue';
import axios from "axios";

export default {
  name: "HeaderApp",
  methods: {
    toggleMenu() {
      document.querySelector(".nav").classList.toggle("active");
    },
    toggleAccount() {
      if (this.isLogged) {
        window.location.href = '/account';
      } else {
        if (!this.isLogged) {
          this.showModal = true;
          return
        }
      }

    },
    updateMsg(newMsg) {
      console.log("newMsg", newMsg);
      this.msg = newMsg;
    },
    closeModal() {
      this.showModal = false
    },

    openSearch(){
      this.showSearch = true
    },
    searchProduct() {
      if (!this.showSearch) {
        this.showSearch = true
      } else {
        if (this.searchValue.length < 3) {
          return false
        } else {
          let params = `&auth=${this.user.username}:${this.user.auth_key}&str=${this.searchValue}`
          axios
              .get(this.apiUrl + "api/product/product-autocomplete" + params)
              .then((response) => {
                if (response.data.length != 0) {
                  console.log('searchProduct', response)
                  this.searchResult = true
                  this.searchProducts = []
                  this.searchProducts.push(response.data)
                  console.log('this.searchProducts', this.searchProducts)
                } else {
                  console.log('response.data.length Null')
                }
              })
        }
      }


    },
    openProduct(id){
      window.location.href = '/product/' + id;
    }
  },
  watch: {
    searchValue(newValue, oldValue) {
      if (this.searchValue.length < 3) {
        return false
      } else {
        this.searchProduct()
      }
      console.log(oldValue)
    }
  },
  data() {
    return {
      apiUrl: this.$store.getters.getApiUrl,
      user: this.$store.getters.getUser,
      showModal: false,
      isLogged: false,
      showSearch: false,
      searchValue: '',
      searchResult: false,
      searchProducts: []

    };
  },
  components: {
    PopupOrderVue
  },
  mounted() {
    this.isLogged = this.$store.getters.getLogged
  }

};
</script>

<template>
  <div class="header">
    <img
        src="../../assets/img/lineHeaderBlack.svg"
        alt=""
        class="absolute black"
    />
    <img
        src="../../assets/img/lineHeaderGreen.svg"
        alt=""
        class="absolute green"
    />
    <div class="header__wrapper">
      <div class="container">
        <div class="header__content">
          <a href="/" class="logo">
            <img class="logo__desktop" src="../../assets/img/logo.svg" alt=""/>
            <img
                class="logo__mobile"
                src="../../assets/img/logo-mob.svg"
                alt=""
            />
          </a>
          <div class="number number__mobile">
            <a href="tel:+79780990909" class="header__tel">+7 978 099 09 09</a>
            <div class="header__dscr">Обратный звонок</div>
          </div>
          <nav>
            <ul class="nav">
              <li><a href="/shop" categoryId="0">Каталог</a></li>
              <li><a href="#">Новости</a></li>
              <li class="submenu-wrapper">
                <a href="/service">Информация</a>
                <ul class="submenu">
                  <li><a href="/service">Услуги</a></li>
                  <li><a href="/delivery">Доставка и оплата</a></li>
                </ul>
              </li>
              <li><a href="/about">О компании</a></li>
              <li><a href="/contacts">Контакты</a></li>
            </ul>
          </nav>
          <div class="header__right">
            <div class="icons">
              <div class="search">
                <div class="search-block" id="searchBlock">
                  <input class="search-input" v-model="searchValue" type="text" v-if="showSearch">
                  <svg :class="this.showSearch ? 'icon-input' : ''" @click="openSearch()"
                       xmlns="http://www.w3.org/2000/svg"
                       width="25"
                       height="24"
                       viewBox="0 0 20 20"
                       fill="none">
                  <g clip-path="url(#clip0_1131_5645)">
                    <path
                        d="M11.5309 3.03391C10.4035 1.91445 8.89638 1.29736 7.29432 1.29736C5.69226 1.29736 4.1891 1.91445 3.05777 3.03391C0.723908 5.348 0.723908 9.12173 3.05777 11.4358C4.1891 12.5553 5.69226 13.1724 7.29432 13.1724C8.89638 13.1724 10.3995 12.5553 11.5309 11.4358C12.6622 10.3163 13.2832 8.82505 13.2832 7.23486C13.2832 5.64467 12.6622 4.15338 11.5309 3.03391ZM11.1986 11.1035C10.1582 12.132 8.76979 12.7016 7.29827 12.7016C5.82676 12.7016 4.43831 12.132 3.39796 11.1035C1.24606 8.96746 1.24606 5.49831 3.39796 3.36619C4.43831 2.33771 5.82676 1.76809 7.29827 1.76809C8.76979 1.76809 10.1582 2.33771 11.1986 3.36619C13.3505 5.50227 13.3505 8.97141 11.1986 11.1035Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M19.5451 17.2152L15.5578 13.2634L14.731 12.4446C14.644 12.3576 14.4818 12.3576 14.3948 12.4446L14.2682 12.5672C14.2563 12.5396 14.2405 12.5158 14.2168 12.4921L13.2002 11.4834C14.1021 10.2571 14.5886 8.7856 14.5886 7.23497C14.5886 5.30063 13.8291 3.48497 12.4525 2.1163C11.0799 0.755538 9.24446 0 7.2943 0C5.34415 0 3.5087 0.755538 2.13608 2.12025C-0.712025 4.94066 -0.712025 9.52927 2.13608 12.3536C3.51266 13.7184 5.34415 14.4699 7.29826 14.4699C8.86076 14.4699 10.3481 13.9873 11.5823 13.0934L12.5989 14.1021C12.6226 14.1258 12.6464 14.1416 12.674 14.1535L12.5475 14.2761C12.4565 14.3671 12.4565 14.5134 12.5475 14.6084L13.4059 15.4589L17.3655 19.3829C17.6543 19.6717 18.0419 19.8339 18.4573 19.8339C18.8726 19.8339 19.2563 19.6756 19.549 19.3829C20.1503 18.7856 20.1503 17.8165 19.549 17.2191L19.5451 17.2152ZM2.46835 12.0214C-0.189873 9.38291 -0.189873 5.08703 2.46835 2.45253C3.75396 1.17484 5.47073 0.470728 7.2943 0.470728C9.11788 0.470728 10.8347 1.17484 12.1203 2.44858C13.4098 3.72627 14.1179 5.42326 14.1179 7.23497C14.1179 9.04668 13.4098 10.7437 12.1203 12.0174C10.8347 13.2911 9.12184 13.9953 7.2943 13.9953C5.46677 13.9953 3.75396 13.2911 2.46835 12.0174V12.0214ZM12.9312 13.7698L11.9581 12.8046C12.1282 12.6622 12.2943 12.5158 12.4565 12.3536C12.6187 12.1954 12.769 12.0293 12.9114 11.8592L13.8845 12.8244C13.9082 12.8481 13.9359 12.8639 13.9597 12.8758L12.9826 13.8449C12.9707 13.8172 12.9549 13.7935 12.9312 13.7698ZM14.5649 12.943L15.0593 13.4335L13.568 14.9565L13.0498 14.4422L14.5649 12.943ZM19.2089 19.0506C19.0071 19.2524 18.7381 19.3631 18.4533 19.3631C18.1685 19.3631 17.8956 19.2524 17.6938 19.0506L13.9003 15.2888L15.3916 13.7658L19.2089 17.5514C19.6242 17.9628 19.6242 18.6353 19.2089 19.0506Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M10.3402 3.10132L10.1543 3.33075C10.2611 3.41382 10.3679 3.50875 10.4668 3.60369L10.6764 3.39404C10.5696 3.29119 10.4589 3.1923 10.3442 3.10132H10.3402Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M7.12427 2.11618L7.14009 2.40495C8.08946 2.33375 9.04278 2.579 9.82996 3.09324L9.9961 2.85194C9.1575 2.3021 8.14088 2.04102 7.12427 2.11618Z"
                        fill="#1F1F1F"/></g>
                  <defs>
                    <clipPath id="clip0_1131_5645">
                      <rect width="20" height="20" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                  <div class="search-block-result" v-if="this.searchResult">
                    <div class="search-res" v-for="prod in searchProducts[0]" :key="prod.id" @click="openProduct(prod.id)">
                      {{ prod.name }}
                    </div>
                  </div>
                </div>

              </div>
              <a href="/wishlist" class="wishlist">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                  <g clip-path="url(#clip0_1131_5651)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.65654 16.4932C7.59214 16.4932 7.54469 16.4627 7.49385 16.4289L1.23357 10.1684C-1.38646 7.54969 0.430273 3 4.20949 3C5.34834 3 6.38889 3.4404 7.18541 4.21957L7.67349 4.7074L8.16156 4.21957C9.78849 2.59348 12.456 2.59348 14.0998 4.21957C15.7437 5.85922 15.7437 8.52535 14.0998 10.1548L7.83957 16.4119C7.78873 16.4763 7.72433 16.4932 7.65993 16.4932H7.65654ZM1.55895 4.56173C0.111667 6.02522 0.111667 8.37967 1.55895 9.84655L7.65654 15.9275L13.7575 9.833C15.2217 8.36951 15.2217 6.01167 13.7575 4.54818C12.2933 3.08469 9.93424 3.10163 8.47 4.54818C7.52096 5.49335 7.79551 5.49674 6.84308 4.54818C5.36867 3.07114 3.03675 3.11857 1.55895 4.56173Z"
                        fill="#1F1F1F"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.65651 14.1524C7.44976 14.1524 7.33791 13.9051 7.49382 13.7628L12.5847 8.67447C13.3982 7.86142 13.3982 6.54361 12.5847 5.74749C12.3577 5.52052 12.7135 5.20546 12.9101 5.42228C13.9032 6.41487 13.9032 8.02403 12.9101 9.01324L7.8192 14.1016C7.78531 14.1185 7.72091 14.149 7.65651 14.149V14.1524ZM2.56898 9.06406C2.09446 9.06406 0.887818 6.92303 2.40628 5.40534C3.39939 4.41274 5.00937 4.41274 5.99908 5.40534C6.22617 5.6357 5.87028 5.94737 5.67369 5.73056C4.86023 4.91751 3.54174 4.91751 2.74523 5.73056C1.94871 6.54361 1.93176 7.86142 2.74523 8.65753C2.92148 8.83031 2.74862 9.06406 2.56559 9.06406H2.56898Z"
                        fill="#1F1F1F"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.3442 16.4932C12.2798 16.4932 12.229 16.4627 12.1815 16.4288L9.8394 14.0879C9.60553 13.8542 9.97837 13.5594 10.1648 13.7627L12.3442 15.9275L18.4418 9.83297C19.906 8.36948 19.906 6.01164 18.4418 4.54816C17.1572 3.26422 15.0761 3.10161 13.5949 4.17551C13.3644 4.35506 13.0661 3.99935 13.3203 3.80286C14.9812 2.60023 17.3199 2.77977 18.7672 4.22632C20.411 5.86597 20.411 8.53209 18.7672 10.1616L12.5069 16.4187C12.456 16.483 12.4086 16.5 12.3442 16.5V16.4932Z"
                        fill="#1F1F1F"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.3442 14.1523C12.1374 14.1523 12.0256 13.905 12.1815 13.7627L17.2724 8.67434C18.0757 7.87146 18.0757 6.55025 17.2724 5.74737C17.0419 5.52039 17.4012 5.20534 17.5978 5.42215C18.5909 6.50621 18.5638 8.04762 17.5978 9.03005L12.5069 14.1184C12.456 14.1184 12.395 14.1489 12.3442 14.1489V14.1523Z"
                        fill="#1F1F1F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1131_5651">
                      <rect width="20" height="20" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a @click="toggleAccount" class="account">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="25"
                    viewBox="0 0 20 21"
                    fill="none">
                  <path
                      d="M13.6732 3.05224L13.458 3.20595C13.5327 3.30696 13.6029 3.41237 13.6644 3.51777L13.8972 3.38162C13.8269 3.26744 13.7523 3.15325 13.6732 3.04785V3.05224Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M11.0777 1.45361L11.025 1.71273C11.8726 1.86644 12.6544 2.30122 13.2297 2.92924L13.4317 2.75357C12.8212 2.08163 11.9868 1.6205 11.0821 1.45361H11.0777Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M10.2082 0.570927C13.2253 0.570927 15.6759 3.02591 15.6759 6.03865C15.6759 9.05138 13.2209 11.5064 10.2082 11.5064C7.19547 11.5064 4.74048 9.05577 4.74048 6.03865C4.74048 3.02152 7.19547 0.570927 10.2082 0.570927ZM10.2082 0C6.87487 0 4.16956 2.70531 4.16956 6.03865C4.16956 9.37198 6.87487 12.0773 10.2082 12.0773C13.5415 12.0773 16.2468 9.37198 16.2468 6.03865C16.2468 2.70531 13.5459 0 10.2082 0Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M14.8063 14.3961C16.484 14.3961 17.8498 15.762 17.8498 17.4396V19.4291H2.57093V17.4396C2.57093 15.762 3.93237 14.4005 5.61001 14.4005H14.8063M14.8063 13.8296H5.61001C3.61616 13.8296 2 15.4457 2 17.4396V20H18.4207V17.4396C18.4207 15.4457 16.8046 13.8252 14.8063 13.8252V13.8296Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M2.25916 17.6504H18.2671"
                      stroke="#1F1F1F"
                      stroke-width="1.3"
                      stroke-miterlimit="10"/>
                </svg>
              </a>
              <RouterLink to="/compare" class="compare">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 28 28"
                    fill="none">
                  <g clip-path="url(#clip0_1131_11222)">
                    <path
                        d="M13.5 27C6.05475 27 0 20.9452 0 13.5C0 6.05475 6.05475 0 13.5 0C20.9452 0 27 6.05475 27 13.5C27 20.9452 20.9452 27 13.5 27ZM13.5 0.9585C6.588 0.9585 0.9585 6.588 0.9585 13.5C0.9585 20.412 6.58125 26.0415 13.5 26.0415C20.4188 26.0415 26.0415 20.412 26.0415 13.5C26.0415 6.588 20.4188 0.9585 13.5 0.9585Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M13.4999 24.5902C7.38445 24.5902 2.4097 19.6154 2.4097 13.4999C2.4097 7.38442 7.38445 2.40967 13.4999 2.40967C19.6154 2.40967 24.5902 7.38442 24.5902 13.4999C24.5902 19.6154 19.6154 24.5902 13.4999 24.5902ZM13.4999 3.36817C7.91095 3.36817 3.3682 7.91092 3.3682 13.4999C3.3682 19.0889 7.91095 23.6317 13.4999 23.6317C19.0889 23.6317 23.6384 19.0889 23.6384 13.4999C23.6384 7.91092 19.0957 3.36817 13.4999 3.36817Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M9.3757 12.1096C9.2542 12.1096 9.1327 12.0623 9.03145 11.9678L6.84445 9.78084C6.7567 9.69309 6.7027 9.57159 6.7027 9.44334C6.7027 9.31509 6.7567 9.19359 6.84445 9.10584L9.0517 6.89859C9.2407 6.70959 9.54445 6.70959 9.73345 6.89859C9.92245 7.08759 9.92245 7.39134 9.73345 7.58034L7.8637 9.45009L9.7132 11.2996C9.9022 11.4886 9.9022 11.7856 9.7132 11.9813C9.6187 12.0758 9.4972 12.1231 9.3757 12.1231V12.1096Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M19.8179 11.7448C19.5479 11.7448 19.3387 11.5288 19.3387 11.2655V11.063C19.3387 10.4285 18.8257 9.91553 18.1979 9.91553H7.18195C6.9187 9.91553 6.7027 9.69953 6.7027 9.43628C6.7027 9.17303 6.9187 8.95703 7.18195 8.95703H18.1979C19.3589 8.95703 20.2972 9.90203 20.2972 11.0563V11.2588C20.2972 11.522 20.0812 11.738 19.8179 11.738V11.7448Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M17.6108 20.2433C17.4893 20.2433 17.3678 20.196 17.2733 20.1083C17.0843 19.9193 17.0843 19.6155 17.2733 19.4265L19.143 17.5568L17.2935 15.7073C17.1045 15.5183 17.1045 15.2213 17.2935 15.0255C17.4825 14.8365 17.7795 14.8365 17.9752 15.0255L20.1623 17.2125C20.3513 17.4015 20.3513 17.6985 20.1623 17.8875L17.955 20.0948C17.8605 20.1893 17.739 20.2298 17.6108 20.2298V20.2433Z"
                        fill="#1F1F1F"/>
                    <path
                        d="M19.818 18.036H8.80872C7.64772 18.036 6.70947 17.091 6.70947 15.9368C6.70947 15.6735 6.92547 15.4575 7.18872 15.4575C7.45197 15.4575 7.66797 15.6735 7.66797 15.9368C7.66797 16.5713 8.18097 17.0843 8.80872 17.0843H19.818C20.0812 17.0843 20.2972 17.3003 20.2972 17.5635C20.2972 17.8268 20.0812 18.0428 19.818 18.0428V18.036Z"
                        fill="#1F1F1F"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1131_11222">
                      <rect width="27" height="27" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </RouterLink>
              <RouterLink to="/cart" class="cart">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="23"
                    viewBox="0 0 20 20"
                    fill="none">
                  <path
                      d="M10.1219 19.2581H4.50374C2.9761 19.2581 1.70614 18.048 1.61411 16.5065L1.00214 6.51709C0.983736 6.24561 1.08496 5.97873 1.26902 5.78088C1.45307 5.58762 1.69694 5.46799 1.97762 5.48179L10.1265 5.56922V6.2134L1.97302 6.12598C1.8994 6.11677 1.80277 6.15818 1.73835 6.2272C1.67393 6.29622 1.64172 6.38365 1.64632 6.48028L2.2537 16.4743C2.32732 17.6753 3.3166 18.6185 4.50374 18.6185H10.1219V19.2627V19.2581Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M15.5009 19.258H9.88269V18.6138H15.5009C16.688 18.6138 17.6773 17.6705 17.7509 16.4696L18.3583 6.47556C18.3629 6.37893 18.3307 6.29151 18.2663 6.22249C18.2065 6.15807 18.1282 6.10746 18.0316 6.12126L9.88269 6.20868V5.5645L18.0224 5.47708C18.2893 5.46787 18.547 5.58291 18.731 5.77616C18.9151 5.97402 19.0163 6.24089 18.9979 6.51237L18.3905 16.5064C18.2985 18.0479 17.0285 19.258 15.5009 19.258Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M18.073 15.7195H1.90857V16.3637H18.073V15.7195Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M6.77216 7.89736C6.59271 7.89736 6.45007 7.75472 6.45007 7.57527V4.96173C6.45007 2.07671 8.55747 1 9.88265 1C10.0621 1 10.2047 1.14264 10.2047 1.32209C10.2047 1.50154 10.0621 1.64418 9.88265 1.64418C8.80594 1.64418 7.09426 2.54604 7.09426 4.96173V7.57527C7.09426 7.75472 6.95162 7.89736 6.77216 7.89736Z"
                      fill="#1F1F1F"/>
                  <path
                      d="M13.099 7.89736C12.9195 7.89736 12.7769 7.75472 12.7769 7.57527V4.96173C12.7769 2.54604 11.0652 1.64418 9.98847 1.64418C9.80902 1.64418 9.66638 1.50154 9.66638 1.32209C9.66638 1.14264 9.80902 1 9.98847 1C11.3137 1 13.421 2.07671 13.421 4.96173V7.57527C13.421 7.75472 13.2784 7.89736 13.099 7.89736Z"
                      fill="#1F1F1F"/>
                </svg>
              </RouterLink>
            </div>
            <div class="number number__desktop">
              <a href="tel:+79780990909" class="header__tel"
              >+7 978 099 09 09</a
              >
              <div class="header__dscr">Обратный звонок</div>
            </div>
            <div class="burger" @click="toggleMenu">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none">
                <rect
                    x="0.5"
                    y="0.5"
                    width="46.9136"
                    height="47.0394"
                    rx="2.5"
                    fill="white"
                    stroke="black"/>
                <rect
                    x="7.27869"
                    y="11.6445"
                    width="33.482"
                    height="2.91148"
                    fill="#1F1F1F"/>
                <rect
                    x="7.27869"
                    y="21.834"
                    width="33.482"
                    height="2.91148"
                    fill="#1F1F1F"/>
                <rect
                    x="7.27869"
                    y="32.0254"
                    width="33.482"
                    height="2.91148"
                    fill="#1F1F1F"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopupOrderVue :msg="'login'"
                 v-if="showModal"
                 v-on:closePopup="showModal = false"
                 v-on:update:msg="updateMsg"
  ></PopupOrderVue>
</template>

<style scoped>
.absolute {
  position: absolute;
}

.number__mobile {
  display: none;
}

.absolute.green {
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  max-width: 100vw;
}

.absolute.black {
  bottom: -3px;
  left: 0;
  height: 3px;
  width: 100%;
  max-width: 100vw;

}

.submenu-wrapper {
  position: relative;
  overflow: hidden;
}

.submenu-wrapper:hover {
  overflow: visible;
}

.submenu-wrapper:hover .submenu {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.submenu {
  position: absolute;
  top: 100%;
  left: calc(50% - 75px);
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  visibility: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  width: 150px;
}

.submenu li a {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 14px;
}

.header {
  position: relative;
}

.logo__mobile {
  display: none;
}

.burger {
  display: none;
}

.header__wrapper {
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

ul {
  display: flex;
  align-items: center;
  gap: 47px;
}

li a {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

li a.active {
  color: #397e33;
}

.header__right {
  display: flex;
  align-items: center;
  gap: 33px;
}

.icons {
  display: flex;
  align-items: center;
  gap: 18px;
}

.header__dscr {
  color: #3f8838;
  font-family: "PT Sans Narrow";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.number {
  text-align: right;
}

.header__tel {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a.logo {
  transform: translateY(45px);
  flex-shrink: 0;
}

.search-input {
  border: 1px solid #d9d9d9;
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  padding: 5px 10px;
  width: 300px;
}

.search-input::placeholder {
  color: #d9d9d9;
}

.search-block {
  position: relative;
}

.icon-input {
  position: absolute;
  top: 8px;
  right: 8px;
}

.search-block-result {
  position: absolute;
  background: #fff;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 5px;
}

.search-res:hover{
  color: #42B239;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 1200px) {
  .burger {
    display: block;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 10px 0;
    position: fixed;
    top: 71px;
    right: 0;
    background-color: #fff;
    padding: 30px;
    transform: translateX(100%);
  }

  .nav.active {
    transform: translateX(0);
  }

  .icons {
    gap: 15px;
  }

  .header__right {
    gap: 25px;
  }

  .number__mobile {
    display: block;
    margin-right: auto;
  }

  .number__desktop {
    display: none;
  }

  .header__content {
    gap: 26px;
  }

  .header__right {
    gap: 26px;
  }

  a.logo {
    transform: translateY(0px);
  }

  .logo__desktop {
    display: none;
  }

  .logo__mobile {
    display: block;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 550px) {
  .icons {
    display: none;
  }

  .number__mobile {
    margin: 0;
    text-align: center;
  }

  .header {
    margin-bottom: 27px;
  }
}
</style>