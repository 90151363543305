<script>
import MainBtn from "../ui/main-btn.vue";
export default {
  name: "HomeAdvantages",
  components: {
    MainBtn,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      advantages: [
        {
          id: 1,
          title: "Замена элементов питания",
          text: 'Первая замена батарейки - бесплатно! Акция действует на все часы купленные в сети "Библиотека часов", даже в послегарантийный период. Это предложение сэкономит Вам время и деньги.',
          url: '/news',
        },
        {
          id: 2,
          title: 'Почему "Библиотека" часов?',
          text: 'Нам хотелось выбрать название, которое отображает нашу деятельность. Но при этом будет запоминающимся и необычным. Есть несколько ассоциаций на которые мы опирались в своем выборе.',
          url: '/about',
        },
        {
          id: 3,
          title: "Сделаем часы уникальными!",
          text: "В эпоху самовыражения, мы решили шагать в ногу со временем. Для этого мы установили современное оборудование для лазерной гравировки, которое поможет сделать Ваши часы уникальными и неповторимыми. ",
          url: '/about',
        },
        {
          id: 4,
          title: "Сделаем часы уникальными!",
          text: 'Мы не только продаем новые часы, но и имеем все необходимые инструменты для того, чтобы преобразить и обновить ваши любимые и дорогие сердцу часы. Заменим батарейку, установим ремешок, отполируем корпус и стекло.',
          url: '/about',
        },
        {
          id: 5,
          title: "Всё, что касается часов",
          text: 'Мы можем дать вашим часам вторую жизнь! В наших магазинах вы найдете не только наручные и настенные часы. А так же все, что может касаться их ремонта и обслуживания! В наличии более 1000 ремешков. Кожаные, каучуковые, силиконовые, керамические и из нержавеющей стали! Укорачиваем и делаем замену на месте и бесплатно. Все виды батареек. Для замены батарейки, мы рекомендуем обращаться в наш салон по адресу Большая Морская 52.',
          url: '/about',
        },
        {
          id: 5,
          title: "Официальный сервис",
          text: 'Мы не только продаем часы! Но и возвращаем к жизни сломанные или потерявшие внешний вид модели. Наш мастер производит гарантийный и пост гарантийный ремонт.',
          url: '/about',
        }
      ]
    }
  }
};
</script>

<template>
  <div class="home-advantages">
    <div class="container">
      <div class="home-advantages__content">
       <div class="home-advantages__head">
            <h2 class="head-h2">Наши преимущества</h2>
            <div class="line"></div>
            <div class="sub-head-h2">
                для вас
            </div>
       </div>
        <div class="home-advantages__items">
          <div class="home-advantages__item"
            v-for="item in advantages" :key="item.id">
            <img src="../../assets/img/itemAdv.png" alt="">
            <div class="home-advantages__itemWrapper">
                <div class="home-advantages__itemContent">
                    <h3 class="head-h3">
                      {{ item.title }}
                    </h3>
                    <p class="description">
                      {{ item.text }}
                    </p>
                </div>
                <main-btn :msg="'Подробнее'" :link="item.url"></main-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-advantages__content{
    display: flex;
    flex-direction: column;
    gap: 36px;
}
.home-advantages__items{
    display: flex;
    gap: 35px;
    justify-content: center;
    flex-wrap: wrap;
}
.home-advantages__head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.home-advantages__item{
    padding: 23px;
    position: relative;
    width: calc(33% - 20px);
    overflow: hidden;
    height: 593px;
}
.home-advantages__item img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    transition: all 0.5s ease;
}
.home-advantages__itemWrapper{
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    padding: 161px 38.5px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.home-advantages__itemContent{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
}
.home-advantages__item:hover .home-advantages__itemWrapper{
    border: 1px solid #397E33;
}
.home-advantages__item:hover img{
    transform: scale(1.1);
    transition: all 0.5s ease;
}
.line{
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    align-self: flex-end;
}
.head-h2{
    white-space: nowrap;
}
.sub-head-h2{
    white-space: nowrap;
}
@media screen and (max-width: 1440px) {
  .home-advantages__item{
    height: 450px;
    padding: 17px;
  }
  .home-advantages__itemWrapper{
    padding: 122px 35px 23px;
  }
}
@media screen and (max-width: 1024px) {   
  .home-advantages__item{
    width: calc(50% - 20px);
    height: 364px;
    padding: 14px;
  }
  .home-advantages__itemWrapper{
    padding: 60px 18px 21px;
    padding-top: 60px;
    padding-bottom: 21px;
  }
  
}
@media screen and (max-width: 550px) {
  .home-advantages__item{
    width: 100%;
  }
  .home-advantages__items{
    gap: 10px;
  }
  
}
</style>
