<script>
export default {
  name: "main-btn",
  props: {
    msg: String,
    link: String,
    className: {
      type: String,
      required: false,
    },
  },
};
</script>

<template>
  <a :class="'main-btn ' + className"> {{ msg }} </a>
</template>

<style scoped>
.main-btn {
  border: 1px solid #fff;
  padding: 15px;
  width: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.3s;
}
.main-btn.black {
    border: 1px solid #1f1f1f;
    color: #1f1f1f;
}
.main-btn.green{
  background: #397e33;
  border: 1px solid #397e33;
  color: #fff;
}
.main-btn:hover {
    transition: all 0.3s;
    border: 1px solid #397E33;
    color: #397E33;
}
.main-btn.green:hover {
    transition: all 0.3s;
    border: 1px solid #397E33;
    color: #397E33;
    background: #fff;
}
@media screen and (max-width: 768px) {
  .main-btn{
    font-size: 16px;
    padding: 12.5px;
    width: 206px;
  }
}
</style>