<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import HomeAboutBlock from "../blocks/HomeAboutBlock.vue";
import mainBtnVue from "../ui/main-btn.vue";
export default {
  name: "AboutPage",
  components: {
    HeaderApp,
    FooterApp,
    HomeAboutBlock,
    mainBtnVue,
  },
  props: {
    categoryId: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
};
</script>

<template>
  <HeaderApp></HeaderApp>

  <div class="main-block">
    <div class="container h100">
      <div class="main-block__content">
        <div class="main-block__text">
          Мы, Евгений и Анастасия - <br>
          владельцы салонов <br>
          Библиотека Часов
          <mainBtnVue
            style="margin-top: 30px"
            :link="'#modal'"
            :msg="'Купить'"
          ></mainBtnVue>
        </div>
      </div>
    </div>
  </div>

  <HomeAboutBlock></HomeAboutBlock>

  <FooterApp></FooterApp>
</template>

<style scoped>
.main-block {
  background-image: url(../../assets/img/main.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  height: calc(100vh - 89px);
  margin-bottom: 81px;
}
.main-block__content {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 156px;
}
.main-block__text {
  color: #fff;
  font-family: "PT Sans Narrow";
  font-size: 58.77px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
}
@media screen and (max-width: 1028px) {
  .main-block__content {
    padding-left: 41px;
  }
  .main-block__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 48.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .main-block {
    background-position: center;
  }
}
@media screen and (max-width: 550px) {
  .main-block__content {
    padding-left: 0px;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20%;
  }
  .main-block__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 32.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .main-block {
    background-position: center;
  }
  
}
</style>