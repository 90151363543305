import { createStore} from "vuex";

export default createStore({
    state:{
        user: {
            user_id: 2,
            username: 'guest', 
            auth_key: 'B89M5gVTJbiMZW-0gHsblJ-6-4yt7aw7',
            email:'',
            role:{
                item_name:'guest'
            },
            name:'',
            profile:{},
            address:{},
            isLogged: false,
            cart: {
                id: '',
                cart: {},
                productIds:[]
            },
            wishlistProductIds: [],
            compareProductIds: []
        },
        isMobile: false,
        apiUrl: `https://bch.bibliotekachasov.ru/web/index.php?r=`,
        apiDomain: 'https://bch.bibliotekachasov.ru',
        flash_messsage: {
            title: '',
            massage: '',
            type: ''
        },
    },
    mutations:{
        setUser(state, user){
            state.user = user
        },
        setUserData(state, user){
            if(user.user_id)     {
                console.log('user.user_id', user.user_id); state.user.user_id = user.user_id}
            if(user.username)    { state.user.username = user.username}
            if(user.auth_key)    { state.user.auth_key = user.auth_key}
            if(user.tg)          { state.user.tg = user.tg}
            if(user.email)       { state.user.email = user.email}
            if(user.role)        { state.user.role = user.role}
            if(user.profile)     { state.user.profile = user.profile}
            if(user.address)     { state.user.address = user.address}
        },
        setLogged(state, logged){
            state.user.isLogged = logged
        },
        setIsMobile(state, isMobile){
            state.isMobile = isMobile
        },
        setFlashMessage(state, flashMessage) {
            state.flash_messsage = flashMessage
        },
        setCart(state, cart) {
            state.user.cart = cart
        },
        setAddress(state, address) {
            state.user.address = address
        },
        setCartProductIds(state, productIds) {
            state.user.cart.productIds = productIds
        },
        setWishlistProductIds(state, wishlistProductIds) {
            state.user.wishlistProductIds = wishlistProductIds
        },
        setCompareProductIds(state, compareProductIds) {
            state.user.compareProductIds = compareProductIds
        }
    },
    getters:{
        getUser:state => {

            return state.user
        },
        getIsMobile:state => {

            return state.isMobile
        },
        getLogged:state => {

            return state.user.isLogged
        },
        getApiUrl:state => {

            return state.apiUrl
        },
        getApiDomain:state => {

            return state.apiDomain
        },
        getFlashMessage:state => {
            return state.flash_messsage
        },
        getCart:state => {
            return state.user.cart
        },
        getAddress:state => {
            return state.user.address
        },
        getCartProductIds:state => {
            return state.user.cart.productIds
        },
        getWishlistProductIds:state => {
            return state.user.wishlistProductIds
        },
        getCompareProductIds:state => {
            return state.user.compareProductIds
        }
    },
})