<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import BreadCrumbs from "../ui/BreadCrumbs.vue";
import axios from "axios";
import MiniProduct from "@/components/product/MiniProduct.vue";
import Cookies from "js-cookie";
export default {
  name: "WishlistPage",
  components: {
    MiniProduct,
    HeaderApp,
    FooterApp,
    BreadCrumbs,
  },
  data() {
    return {
      products: [],
      product: [],
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  methods: {
    getProducts() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}`;
      axios
        .get(
          this.apiUrl + "api/product/get-save-product-by-user-id" + params
        )
        .then((res) => {
          this.products = res.data;
          if(res.data.status){
            let ids = this.products.map(item => item.product_id);
            Cookies.set("wishlist", JSON.stringify(ids), { expires: 7 });
            this.$store.commit('setWishlistProductIds', ids)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<template>
  <HeaderApp></HeaderApp>

  <BreadCrumbs></BreadCrumbs>
  <div class="wishlist-page">
    <div class="container">
      <div class="sub-head-h2 black">Избранные товары</div>
      <div class="wishlist-page__content">
        <div class="products">
          <MiniProduct v-for="item in products" :key="item.id" :product="item.product"></MiniProduct>
        </div>
      </div>
    </div>
  </div>

  <FooterApp></FooterApp>
</template>
<style scoped>
.wishlist-page__content {
  display: flex;
  margin-bottom: 50px;
  margin-top: 30px;
  justify-content: center;
}
.wishlist-page__product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.products {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0px;
  margin-bottom: 35px;
}
@media screen and (max-width: 1440px) {
  .products {
    grid-template-columns: repeat(4, 1fr);
  }

}
@media screen and (max-width: 1024px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }


}
</style>