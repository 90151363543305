<!--
Принимает массив
 :breadCrumbs="[
                { title: `${manufacturer}`, url: '/' },
                { title: `${product?.name }`, active: true },
              ]
-->
<script>
export default {
  name: "BreadCrumbs",
  props:{
    breadCrumbs: {
      type: Array,
      required: false,
    },
  }
};
</script>

<template>
  <div class="bread-crumbs">
    <div class="container">
      <div class="bread-crumbs__content">
        <div class="bread-crumbs__item">
          <a href="/" class="bread-crumbs__link">Главная</a>
        </div>
        <div class="bread-crumbs__item">
          <a href="/shop" class="bread-crumbs__link">Каталог</a>
        </div>
        <div v-for="(crumb, index) in breadCrumbs" :key="index" class="bread-crumbs__item">
          <span v-if="crumb.active" class="bread-crumbs__link active">{{ crumb.title }}</span>
          <a v-else :href="crumb.url" class="bread-crumbs__link">{{ crumb.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bread-crumbs__content {
  display: flex;
  gap: 17px;
}

.bread-crumbs__item a {
  color: #b5b5b5;
  font-family: "PT Sans Narrow";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.bread-crumbs__item{
  position: relative;
}
.bread-crumbs__item:not(:last-child)::after {
  content: "-";
  color: #b5b5b5;
  font-family: "PT Sans Narrow";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: absolute;
  right: -10px;
  top: 0;
}
.bread-crumbs__item.active a{
  color: #1F1F1F;
}
.bread-crumbs{
  margin-top: 89px;
  margin-bottom: 35px;
}
@media screen and (max-width: 550px) {
  .bread-crumbs{
    display: none;
  }
}
</style>