<script>
import HeaderApp from "../blocks/header.vue";
import FooterApp from "../blocks/footer.vue";
import BreadCrumbs from "../ui/BreadCrumbs.vue";
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper/modules";
import Cookies from "js-cookie";
import MiniProduct from "../product/MiniProduct.vue";
import PopupOrderVue from "@/components/ui/PopupOrder.vue";

export default {
  name: "ProductPage",
  components: {
    PopupOrderVue,
    HeaderApp,
    FooterApp,
    BreadCrumbs,
    Swiper,
    SwiperSlide,
    MiniProduct,
  },
  data() {
    return {
      product: {},
      productId: null,
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      cart: this.$store.getters.getCart,
      isInWishlist: this.$store.getters.getWishlistProductIds,
      isInCompare: this.$store.getters.getCompareProductIds,
      swiperInstance: null,
      isHovered: false,
      scrollDirection: "",
      manufacturer: "",
      attribute: [],
      quantityViewProducts: 0,
      viewProducts: [],
      inCart: false,
      showModal: false,
      inWishlist: false,
      inCompare: false,
      showModalLogin: false
    };
  },
  methods: {
    addToWishlist() {
      if(this.user.user_id == 2){
        this.showModalLogin = true
        return false
      }
      let params = `&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}&product_id=${this.productId}`;

      axios
          .post(this.apiUrl + "api/product/add-save-product-by-user-id" + params)
          .then((response) => {
            console.log("fetchProduct", response);
            if (response.data.status) {
              Cookies.set("wishlist", JSON.stringify(response.data.productsLike), { expires: 7 });
              this.$store.commit('setWishlistProductIds', response.data.productsLike)
              this.inWishlist = !this.inWishlist;
            }
          })
          .catch((error) => {
            console.error("Error adding to wishlist:", error);
          });
    },
    addToCompare() {
      if(this.user.user_id == 2){
        this.showModalLogin = true
        return false
      }
      let params = `&auth=${this.user.username}:${this.user.auth_key}&user_id=${this.user.user_id}&product_id=${this.productId}`;

      axios
          .get(
              this.apiUrl + "api/product/add-compare-product-by-user-id" + params
          )
          .then((response) => {
            if (response.data.status) {
              Cookies.set('compare', JSON.stringify(response.data.productsCompare), {expires: 7});
              this.inCompare = !this.inCompare;
            }
          })
          .catch((error) => {
            console.error("Error adding to compare:", error);
          });
    },
    addToCart() {
      let params;
      if (this.cart.id == null) {
        console.log('this.cartId Null', this.cart.id)
        params = `&auth=${this.user.username}:${this.user.auth_key}&product_id=${this.product.id}`;
      } else {
        console.log('this.cartId', this.cart.id)
        params = `&auth=${this.user.username}:${this.user.auth_key}&product_id=${this.product.id}&cart_id=${this.cart.id}`;
        if (this.user.role.item_name == 'user') {
          params += '&user_id=' + this.user.user_id
        }
      }
      axios
          .post(this.apiUrl + "api/cart/add-to-cart" + params)
          .then((response) => {
            console.log("cartAdd", response);

            if (response.data.status) {
              const cartId = response.data.cart_id;
              let cart = {
                id: cartId,
                cart: response.data,
                productIds: response.data
              }
              if (this.cartId == null) {
                this.cartId = cartId;
              }
              if (response.data.status) {
                Cookies.set("setCart", JSON.stringify(cart), {expires: 7});
                this.inCart = true
              }
            }


          })
          .catch((error) => {
            console.log(error)
          })
    },
    onSwiper(swiper) {
      this.swiperInstance = swiper;
      console.log("Swiper initialized", this.swiperInstance); // Debug log
    },
    goToSlide(index) {
      if (this.swiperInstance) {
        console.log("goToSlide", index);
        this.swiperInstance.slideToLoop(index, 0);
      } else {
        console.error("Swiper instance not initialized");
      }
    },
    fetchProduct() {
      this.productId = this.$route.params.id;
      let params = `&auth=${this.user.username}:${this.user.auth_key}&id=${this.productId}`;
      axios
          .get(this.apiUrl + "api/product/get-product-by-id" + params)
          .then((response) => {
            this.product = response.data;
            console.log("this.product", this.product)

            this.attribute = Object.values(
                this.product.attribute.filter(Boolean).reduce((acc, {attr, attrGroup}) => {
                  if (!acc[attrGroup]) {
                    acc[attrGroup] = {attrGroup, attr: []};
                  }
                  acc[attrGroup].attr.push(attr);
                  return acc;
                }, {})
            )
            for (let a of this.product.attribute) {
              if (a.attrGroup && a.attrGroup == "Производитель") {
                this.manufacturer = a.attr;
              }
            }
            this.saveProductIdInCookies(this.productId);
          })
          .catch((error) => {
            console.error("Error fetching product:", this.productId, error);
          });
    },

    saveProductIdInCookies(productId) {
      let viewedProducts = Cookies.get("viewedProducts");
      viewedProducts = viewedProducts ? JSON.parse(viewedProducts) : [];

      if (!viewedProducts.includes(productId)) {
        viewedProducts.push(productId);

        Cookies.set("viewedProducts", JSON.stringify(viewedProducts), {
          expires: 7,
        });
      }
    },
    getViewedProducts() {
      let viewedProducts = Cookies.get("viewedProducts");
      viewedProducts = viewedProducts ? JSON.parse(viewedProducts) : [];
      if (!viewedProducts.length) {
        Cookies.set("viewedProducts", JSON.stringify([]), {expires: 7});
      }
      for (let p of viewedProducts) {
        this.quantityViewProducts += 1;
        let params = `&auth=${this.user.username}:${this.user.auth_key}&id=${p}`;
        console.log("p", params);
        axios
            .get(this.apiUrl + "api/product/get-product-by-id" + params)
            .then((response) => {
              this.viewProducts.push(response.data);
              console.log("viewProductsTHEN", this.viewProducts);
            })
            .catch((error) => {
              console.error("Error fetching viewed products:", error);
            });
      }
    },

    handleScroll() {
      const scrollTop = document.querySelector(".nav__miniPhoto").scrollTop;
      const scrollDelta = scrollTop - this.lastScrollTop;
      this.scrollDirection = scrollDelta > 0 ? "down" : "";
      this.scrollDirection = scrollDelta < 0 ? "up" : "";
      this.lastScrollTop = scrollTop;
    },
    changeTab(tab) {
      this.currentTab = tab;
      console.log("currentTab", this.currentTab);
      let items = document.querySelectorAll(".product__properties-text");
      let links = document.querySelectorAll(".nav__properties a");

      for (let i = 0; i < items.length; i++) {
        links[i].classList.remove("active");
        items[i].classList.remove("active");
        if (items[i].classList.contains(tab)) {
          items[i].classList.add("active");
        }
        if (links[i].classList.contains(tab)) {
          links[i].classList.add("active");
        }
      }
    },
    getBtnCart() {
      let productIds = this.cart.productIds
      return productIds.includes(this.product.id)
    },
    getGreenLike() {
      return this.isInWishlist.includes(this.productId)
    },
    getGreenCompare() {
      return this.isInCompare.includes(this.productId)
    },

  },

  computed: {
    evenFilteredAttribute() {
      return this.attribute.filter((item, index) => index % 2 === 0); // Чётные элементы
    },
    oddFilteredAttribute() {
      return this.attribute.filter((item, index) => index % 2 !== 0); // Нечётные элементы
    }
  },

  created() {
    this.fetchProduct();
  },
  mounted() {
    this.lastScrollTop = 0;
    window.addEventListener("wheel", this.handleScroll);
    this.changeTab("characteristics");
    this.getViewedProducts();
    this.inCart = this.getBtnCart()
    this.inWishlist = this.getGreenLike()
    this.inCompare = this.getGreenCompare()
  },
  beforeUnmount() {
    window.removeEventListener("wheel", this.handleScroll);
  },
  setup() {
    const modules = [Navigation, Pagination, Scrollbar, A11y];
    return {modules};
  },
};
</script>


<template>
  <HeaderApp></HeaderApp>
  <div class="product-head">
    <div class="container">
      <div class="product-head__content">
        <BreadCrumbs :breadCrumbs="[
                { title: `${manufacturer}`, url: '/' },
                { title: `${product?.name }`, active: true },
              ]"></BreadCrumbs>
        <div class="socials">
          Связаться с нами:
          <div class="links">
            <a href="https://vk.com/sev10na10">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="27"
                   height="27"
                   viewBox="0 0 27 27"
                   fill="none">
                <g clip-path="url(#clip0_876_80)">
                  <path
                      d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
                      fill="#4D76A1"/>
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.9903 19.421H14.0499C14.0499 19.421 14.3699 19.3858 14.5333 19.2097C14.6837 19.048 14.6789 18.7443 14.6789 18.7443C14.6789 18.7443 14.6582 17.3225 15.3181 17.1131C15.9686 16.9069 16.8039 18.4872 17.6892 19.0949C18.3587 19.5548 18.8675 19.4539 18.8675 19.4539L21.2347 19.421C21.2347 19.421 22.4731 19.3447 21.8859 18.371C21.8378 18.2914 21.544 17.6507 20.1258 16.3344C18.6415 14.9567 18.8403 15.1795 20.6283 12.7963C21.7172 11.345 22.1526 10.4589 22.0166 10.0794C21.8869 9.71795 21.086 9.81349 21.086 9.81349L18.4206 9.83009C18.4206 9.83009 18.223 9.80314 18.0764 9.89074C17.9332 9.97665 17.8411 10.1769 17.8411 10.1769C17.8411 10.1769 17.4192 11.3 16.8566 12.2551C15.6697 14.2706 15.1951 14.3769 15.0012 14.2518C14.5499 13.9601 14.6626 13.0801 14.6626 12.4549C14.6626 10.5017 14.9588 9.68739 14.0857 9.47658C13.796 9.40655 13.5828 9.36034 12.8418 9.35288C11.8908 9.34302 11.0858 9.35577 10.63 9.5791C10.3268 9.72758 10.0929 10.0585 10.2353 10.0775C10.4115 10.1011 10.8105 10.1851 11.022 10.4731C11.2952 10.8447 11.2855 11.6793 11.2855 11.6793C11.2855 11.6793 11.4424 13.9784 10.919 14.2641C10.5597 14.46 10.0669 14.06 9.00873 12.2315C8.46655 11.2949 8.0572 10.2597 8.0572 10.2597C8.0572 10.2597 7.97827 10.0662 7.83749 9.96269C7.66663 9.83731 7.4279 9.79737 7.4279 9.79737L4.89506 9.81397C4.89506 9.81397 4.51483 9.82456 4.37525 9.98989C4.25108 10.1369 4.36538 10.4411 4.36538 10.4411C4.36538 10.4411 6.34834 15.0804 8.59361 17.4183C10.6524 19.5618 12.9903 19.421 12.9903 19.421Z"
                      fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_876_80">
                    <rect width="27" height="27" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a href="https://wa.com">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none">
                <g clip-path="url(#clip0_876_84)">
                  <path
                      d="M13.5 27C20.9558 27 27 20.9558 27 13.5C27 6.04416 20.9558 0 13.5 0C6.04416 0 0 6.04416 0 13.5C0 20.9558 6.04416 27 13.5 27Z"
                      fill="#29A71A"/>
                  <path
                      d="M19.4523 7.54784C18.0477 6.12927 16.1824 5.2593 14.1929 5.09487C12.2034 4.93043 10.2207 5.48235 8.60227 6.65108C6.98386 7.8198 5.83637 9.52839 5.36677 11.4687C4.89717 13.4089 5.13638 15.4531 6.04124 17.2326L5.153 21.5449C5.14379 21.5878 5.14352 21.6322 5.15224 21.6752C5.16095 21.7182 5.17844 21.759 5.20363 21.7949C5.24052 21.8495 5.29319 21.8915 5.35459 21.9154C5.41599 21.9392 5.48321 21.9438 5.54726 21.9284L9.77368 20.9267C11.5481 21.8086 13.5779 22.0324 15.5019 21.5583C17.4258 21.0842 19.1192 19.9428 20.2806 18.3374C21.442 16.7319 21.9962 14.7664 21.8445 12.7907C21.6929 10.815 20.8452 8.95721 19.4523 7.54784ZM18.1345 18.0656C17.1627 19.0347 15.9113 19.6744 14.5566 19.8945C13.202 20.1147 11.8124 19.9043 10.5837 19.2928L9.99459 19.0014L7.40351 19.615L7.41118 19.5828L7.94812 16.9748L7.65971 16.4057C7.0319 15.1727 6.81043 13.7726 7.02703 12.4061C7.24363 11.0395 7.88718 9.77655 8.8655 8.79813C10.0948 7.56923 11.7618 6.87888 13.5 6.87888C15.2382 6.87888 16.9052 7.56923 18.1345 8.79813C18.1449 8.81013 18.1562 8.82141 18.1682 8.83188C19.3823 10.0639 20.06 11.726 20.0537 13.4557C20.0474 15.1854 19.3575 16.8424 18.1345 18.0656Z"
                      fill="white"/>
                  <path
                      d="M17.9043 16.1522C17.5867 16.6523 17.0851 17.2644 16.4546 17.4163C15.35 17.6832 13.6549 17.4255 11.5455 15.4588L11.5194 15.4358C9.6647 13.716 9.183 12.2847 9.29959 11.1495C9.36402 10.5052 9.90095 9.92223 10.3535 9.54178C10.4251 9.48071 10.5099 9.43723 10.6013 9.41482C10.6926 9.39241 10.7879 9.39169 10.8796 9.41271C10.9713 9.43373 11.0568 9.47591 11.1293 9.53588C11.2017 9.59585 11.2592 9.67195 11.297 9.75808L11.9796 11.2922C12.024 11.3916 12.0404 11.5013 12.0272 11.6094C12.014 11.7175 11.9715 11.82 11.9045 11.9058L11.5593 12.3538C11.4852 12.4463 11.4405 12.5588 11.431 12.6769C11.4214 12.795 11.4474 12.9133 11.5056 13.0165C11.6989 13.3555 12.1622 13.8541 12.6761 14.3159C13.2529 14.8375 13.8927 15.3146 14.2977 15.4772C14.406 15.5214 14.5252 15.5322 14.6397 15.5082C14.7543 15.4841 14.859 15.4263 14.9404 15.3422L15.3408 14.9387C15.4181 14.8625 15.5142 14.8082 15.6193 14.7812C15.7244 14.7543 15.8347 14.7557 15.9391 14.7853L17.5607 15.2455C17.6501 15.273 17.7321 15.3205 17.8004 15.3845C17.8686 15.4485 17.9214 15.5272 17.9545 15.6147C17.9877 15.7022 18.0004 15.7961 17.9917 15.8893C17.983 15.9824 17.9531 16.0723 17.9043 16.1522Z"
                      fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_876_84">
                    <rect width="27" height="27" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a href="https://t.me/bibliotekachasov">
              <img src="../../assets/img/telegram.png" alt="" style="width: 26px">
            </a>
          </div>
        </div>
      </div>
      <div class="product__content">
        <img
            class="product__plash"
            src="../../assets/img/productPlash.png"
            alt=""
        />
        <div class="gallery">
          <div
              class="nav__miniPhoto"
              @mouseover="isHovered = true"
              @mouseleave="isHovered = false"
          >
            <div
                v-for="(item, index) in product?.productPhoto || []"
                :key="item.id"
                class="miniPhoto"
                @click="goToSlide(index)"
            >
              <img
                  :src="apiDomain + '/web/uploads/products/' + item"
                  alt=""
                  class="miniPhoto__img"
              />
            </div>
          </div>
          <div
              class="go-to-scroll"
              :class="{
              hovered: isHovered,
              'scroll-down': isHovered && scrollDirection === 'down',
              'scroll-up': isHovered && scrollDirection === 'up',
            }"
          >
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
            >
              <path
                  d="M15.4353 30.66C15.2998 30.66 15.1698 30.6062 15.074 30.5104C14.9782 30.4145 14.9243 30.2846 14.9243 30.149V25.8704C14.9243 25.7348 14.9782 25.6049 15.074 25.509C15.1698 25.4132 15.2998 25.3594 15.4353 25.3594C15.5708 25.3594 15.7008 25.4132 15.7966 25.509C15.8925 25.6049 15.9463 25.7348 15.9463 25.8704V30.1627C15.9427 30.2958 15.8873 30.4223 15.7919 30.5152C15.6964 30.6081 15.5685 30.6601 15.4353 30.66Z"
                  fill="#181818"
              />
              <path
                  d="M15.4356 30.6596C15.3105 30.6604 15.1894 30.6156 15.0949 30.5336L12.3697 28.149C12.2676 28.0586 12.2055 27.9314 12.1972 27.7953C12.1889 27.6593 12.235 27.5255 12.3254 27.4234C12.4157 27.3213 12.5429 27.2593 12.679 27.251C12.8151 27.2427 12.9489 27.2887 13.051 27.3791L15.7763 29.7637C15.8777 29.8535 15.9397 29.9798 15.9486 30.115C15.9575 30.2502 15.9127 30.3835 15.824 30.4859C15.7756 30.5412 15.7159 30.5854 15.6489 30.6154C15.5818 30.6454 15.509 30.6605 15.4356 30.6596Z"
                  fill="#181818"
              />
              <path
                  d="M15.4353 30.6595C15.3624 30.6598 15.2903 30.6445 15.2239 30.6145C15.1575 30.5845 15.0983 30.5406 15.0504 30.4857C14.9616 30.3833 14.9168 30.25 14.9257 30.1148C14.9346 29.9796 14.9966 29.8533 15.0981 29.7635L17.8233 27.3789C17.9254 27.2886 18.0592 27.2425 18.1953 27.2508C18.3314 27.2591 18.4586 27.3211 18.5489 27.4232C18.6393 27.5253 18.6854 27.6591 18.6771 27.7952C18.6688 27.9312 18.6067 28.0584 18.5046 28.1488L15.7794 30.5334C15.684 30.6162 15.5616 30.6611 15.4353 30.6595ZM15.4353 5.79133C15.2998 5.79133 15.1698 5.73749 15.074 5.64166C14.9782 5.54583 14.9243 5.41586 14.9243 5.28034V0.984622C14.9243 0.849099 14.9782 0.719127 15.074 0.623298C15.1698 0.527469 15.2998 0.473633 15.4353 0.473633C15.5708 0.473633 15.7008 0.527469 15.7966 0.623298C15.8925 0.719127 15.9463 0.849099 15.9463 0.984622V5.27693C15.9467 5.34432 15.9339 5.41113 15.9084 5.47352C15.8829 5.53591 15.8453 5.59265 15.7978 5.64046C15.7503 5.68827 15.6939 5.72621 15.6316 5.75211C15.5694 5.778 15.5027 5.79133 15.4353 5.79133Z"
                  fill="#181818"
              />
              <path
                  class="up"
                  d="M12.6997 3.88729C12.5953 3.88824 12.4931 3.85718 12.4069 3.79829C12.3207 3.73941 12.2546 3.65552 12.2174 3.55793C12.1803 3.46033 12.174 3.35371 12.1993 3.25242C12.2246 3.15112 12.2804 3.06001 12.359 2.99135L15.0843 0.60674C15.134 0.561556 15.1921 0.526596 15.2552 0.503855C15.3184 0.481114 15.3854 0.471037 15.4525 0.474201C15.5195 0.477364 15.5853 0.493705 15.6461 0.522291C15.7068 0.550877 15.7613 0.591149 15.8065 0.640806C15.8517 0.690463 15.8867 0.748533 15.9094 0.811701C15.9321 0.874869 15.9422 0.941898 15.9391 1.00896C15.9359 1.07602 15.9196 1.1418 15.891 1.20255C15.8624 1.2633 15.8221 1.31782 15.7725 1.363L13.0472 3.74762C12.9521 3.8348 12.8286 3.88444 12.6997 3.88729Z"
                  fill="#181818"
              />
              <path
                  class="mouse"
                  d="M18.1675 3.88718C18.0424 3.88799 17.9213 3.84318 17.8269 3.76114L15.1016 1.37652C14.9995 1.28617 14.9375 1.15897 14.9292 1.02289C14.9209 0.886812 14.967 0.753009 15.0573 0.650915C15.1476 0.548821 15.2749 0.4868 15.4109 0.478495C15.547 0.470189 15.6808 0.516281 15.7829 0.606629L18.5082 2.99124C18.5868 3.0599 18.6426 3.15101 18.6679 3.25231C18.6932 3.3536 18.6869 3.46022 18.6498 3.55782C18.6127 3.65541 18.5466 3.7393 18.4603 3.79818C18.3741 3.85707 18.2719 3.88813 18.1675 3.88718ZM17.1149 23.6931H13.756C12.5254 23.6922 11.3454 23.2032 10.4749 22.3334C9.60448 21.4635 9.1146 20.2839 9.11279 19.0533V12.0937C9.1146 10.8631 9.60448 9.68347 10.4749 8.81363C11.3454 7.9438 12.5254 7.45478 13.756 7.45388H17.1149C18.3449 7.45568 19.524 7.9451 20.3937 8.81483C21.2634 9.68457 21.7529 10.8637 21.7547 12.0937V19.0533C21.7529 20.2833 21.2634 21.4624 20.3937 22.3322C19.524 23.2019 18.3449 23.6913 17.1149 23.6931ZM13.756 8.47586C12.7964 8.47676 11.8764 8.8581 11.1976 9.53628C10.5188 10.2145 10.1366 11.1341 10.1348 12.0937V19.0533C10.1366 20.0129 10.5188 20.9325 11.1976 21.6107C11.8764 22.2889 12.7964 22.6702 13.756 22.6711H17.1149C18.0741 22.6702 18.9938 22.2888 19.6721 21.6105C20.3503 20.9322 20.7318 20.0126 20.7327 19.0533V12.0937C20.7318 11.1344 20.3503 10.2148 19.6721 9.53649C18.9938 8.85821 18.0741 8.47676 17.1149 8.47586H13.756Z"
                  fill="#181818"
              />
              <path
                  d="M15.4353 14.506C15.2998 14.506 15.1698 14.4522 15.074 14.3564C14.9782 14.2606 14.9243 14.1306 14.9243 13.9951V11.7092C14.9243 11.5737 14.9782 11.4437 15.074 11.3479C15.1698 11.2521 15.2998 11.1982 15.4353 11.1982C15.5708 11.1982 15.7008 11.2521 15.7966 11.3479C15.8925 11.4437 15.9463 11.5737 15.9463 11.7092V13.9951C15.9463 14.1306 15.8925 14.2606 15.7966 14.3564C15.7008 14.4522 15.5708 14.506 15.4353 14.506Z"
                  fill="#181818"
              />
            </svg>
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>

          <Swiper
              :slidesPerView="1"
              :spaceBetween="41"
              :loop="true"
              :modules="modules"
              :navigation="{ enabled: true, prevSh: '.prev', nextEl: '.nextSh' }"
              @swiper="onSwiper"
              class="mySwiper"
          >
            <div class="arrowsShop">
              <a class="prev prevSh" :class="{ 'grey-icon': isFirstSlide }">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                >
                  <path
                      d="M11.8749 18.9999C11.8748 19.1559 11.9054 19.3103 11.9651 19.4544C12.0248 19.5985 12.1124 19.7294 12.2229 19.8395L24.0979 31.7145C24.5619 32.1785 25.3133 32.1785 25.777 31.7145C26.2407 31.2505 26.241 30.4991 25.777 30.0354L14.7416 18.9999L25.777 7.96449C26.241 7.50047 26.241 6.74908 25.777 6.28536C25.313 5.82164 24.5616 5.82134 24.0979 6.28536L12.2229 18.1604C12.1124 18.2705 12.0248 18.4014 11.9651 18.5454C11.9054 18.6895 11.8748 18.844 11.8749 18.9999Z"
                      fill="#D9D9D9"
                  />
                </svg>
              </a>
              <a class="next nextSh" :class="{ 'grey-icon': isLastSlide }">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                >
                  <path
                      d="M26.125 18.9999C26.1251 19.1559 26.0945 19.3103 26.0347 19.4544C25.975 19.5985 25.8874 19.7294 25.777 19.8395L13.902 31.7145C13.438 32.1785 12.6866 32.1785 12.2229 31.7145C11.7592 31.2505 11.7589 30.4991 12.2229 30.0354L23.2583 18.9999L12.2229 7.96449C11.7589 7.50047 11.7589 6.74908 12.2229 6.28536C12.6869 5.82164 13.4383 5.82134 13.902 6.28536L25.777 18.1604C25.8874 18.2705 25.975 18.4014 26.0347 18.5454C26.0945 18.6895 26.1251 18.844 26.125 18.9999Z"
                      fill="#D9D9D9"
                  />
                </svg>

                <!-- Добавлено применение класса grey-icon -->
              </a>
            </div>
            <swiper-slide
                v-for="item in product?.productPhoto || []"
                :key="item.id"
                class="swiper-slide"
            >
              <img
                  :src="apiDomain + '/web/uploads/products/' + item"
                  alt=""
                  class="product__img"
              />
            </swiper-slide>
          </Swiper>
        </div>
        <div class="info-prod">
          <div class="product__head">
            <h1 class="product__title">
              {{ product?.name }}
            </h1>
            <div class="product__attribute">
              <div class="manufacturer product__attribute-item">
                <div class="attribute__title">Производитель:</div>
                <div class="attribute__value">{{ manufacturer }}</div>
              </div>
              <div class="article product__attribute-item">
                <div class="attribute__title">Артикул:</div>
                <div class="attribute__value">{{ product?.id }}</div>
              </div>
              <div class="quantity product__attribute-item">
                <div class="attribute__title">Наличие:</div>
                <div class="attribute__value">
                  {{
                    product?.quantity > 0
                        ? "в наличии " + product.quantity
                        : "доставка 3-5 дней"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="product_cart">
            <div class="price">
              <div class="price__value">
                {{ product?.price }} <span class="price__currency">₽</span>
              </div>
              <template v-if="product?.old_price">
                <div class="price__old">
                  {{ product?.old_price }}
                  <span class="price__currency">₽</span>
                </div>
                <div class="action">* условия акции</div>
              </template>
            </div>
            <div class="btns">
              <div class="btns__addToCart">
                <button @click="addToCart()" class="btn btn__addToCart">
                  {{ this.inCart ? 'В корзине' : 'В корзину' }}
                </button>
                <button @click="this.showModal = true" class="btn btn__buy">Быстрый заказ</button>
              </div>
              <router-link
                  :to="{
                  name: 'ProductPage',
                  params: { id: parseInt(product.id) + 1 },
                }"
                  class="next-product"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="86"
                    height="53"
                    viewBox="0 0 86 53"
                    fill="none"
                >
                  <rect
                      x="33.5"
                      y="0.5"
                      width="52"
                      height="52"
                      stroke="#D9D9D9"
                  />
                  <path
                      d="M74.7957 28.3959H1.57465C0.70367 28.3959 0 27.6922 0 26.8212C0 25.9502 0.70367 25.2466 1.57465 25.2466H70.9919L61.4358 15.6904C60.8207 15.0753 60.8207 14.0764 61.4358 13.4613C62.0509 12.8462 63.0498 12.8462 63.6649 13.4613L75.9127 25.7091C76.3654 26.1618 76.4983 26.836 76.2522 27.4265C76.0062 28.012 75.4305 28.3959 74.7957 28.3959Z"
                      fill="#D9D9D9"
                  />
                  <path
                      d="M62.5334 40.6577C62.1299 40.6577 61.7264 40.5052 61.4213 40.1952C60.8062 39.5801 60.8062 38.5812 61.4213 37.9661L73.6838 25.7035C74.2989 25.0884 75.2978 25.0884 75.9129 25.7035C76.528 26.3186 76.528 27.3175 75.9129 27.9326L63.6504 40.1952C63.3404 40.5052 62.9369 40.6577 62.5334 40.6577Z"
                      fill="#D9D9D9"
                  />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="product__special">
            <img src="../../assets/img/descrProd.png" alt=""/>
            <div class="product_info-text">
              При заказе онлайн - <span>гравировка в подарок</span>
              <div class="description">
                {{ product?.description }}
              </div>
            </div>
          </div>
          <div class="product__wishlist">
            <div class="add-to-wishlist" @click="addToWishlist()">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
              >
                <g clip-path="url(#clip0_1131_5945)">
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.3363 22.2657C10.2494 22.2657 10.1853 22.2245 10.1167 22.1788L1.66532 13.7271C-1.87172 10.1919 0.580869 4.0498 5.68281 4.0498C7.22026 4.0498 8.625 4.64435 9.7003 5.69623L10.3592 6.3548L11.0181 5.69623C13.2145 3.501 16.8156 3.501 19.0348 5.69623C21.254 7.90975 21.254 11.509 19.0348 13.7088L10.5834 22.1559C10.5148 22.2428 10.4278 22.2657 10.3409 22.2657H10.3363ZM2.10459 6.15814C0.15075 8.13385 0.15075 11.3124 2.10459 13.2926L10.3363 21.5019L18.5726 13.2744C20.5494 11.2986 20.5494 8.11556 18.5726 6.13985C16.5959 4.16414 13.4112 4.18701 11.4345 6.13985C10.1533 7.41583 10.5239 7.4204 9.23815 6.13985C7.24771 4.14585 4.09961 4.20987 2.10459 6.15814Z"
                      :fill="this.inWishlist ? '#397e33' : 'black'"
                  />
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.3364 19.1055C10.0573 19.1055 9.90625 18.7717 10.1167 18.5796L16.9895 11.7103C18.0877 10.6127 18.0877 8.83366 16.9895 7.75891C16.6829 7.45249 17.1634 7.02717 17.4288 7.31986C18.7694 8.65987 18.7694 10.8322 17.4288 12.1677L10.556 19.0369C10.5102 19.0598 10.4233 19.1009 10.3364 19.1009V19.1055ZM3.4682 12.2363C2.82759 12.2363 1.19863 9.34588 3.24856 7.297C4.58925 5.95699 6.76272 5.95699 8.09884 7.297C8.40541 7.60799 7.92496 8.02874 7.65957 7.73604C6.56139 6.63843 4.78143 6.63843 3.70614 7.73604C2.63084 8.83366 2.60796 10.6127 3.70614 11.6875C3.94407 11.9207 3.71071 12.2363 3.46362 12.2363H3.4682Z"
                      :fill="this.inWishlist ? '#397e33' : 'black'"
                  />
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.6646 22.2656C16.5777 22.2656 16.509 22.2245 16.445 22.1788L13.2831 19.0185C12.9674 18.703 13.4707 18.3051 13.7224 18.5795L16.6646 21.5019L24.8963 13.2743C26.8731 11.2986 26.8731 8.11555 24.8963 6.13984C23.1621 4.40652 20.3526 4.187 18.3531 5.63676C18.0419 5.87915 17.6392 5.39895 17.9824 5.13369C20.2245 3.51013 23.3818 3.75252 25.3356 5.70537C27.5548 7.91889 27.5548 11.5182 25.3356 13.718L16.8842 22.165C16.8156 22.2519 16.7515 22.2748 16.6646 22.2748V22.2656Z"
                      :fill="this.inWishlist ? '#397e33' : 'black'"
                  />
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.6646 19.1056C16.3855 19.1056 16.2345 18.7717 16.4449 18.5796L23.3177 11.7104C24.4021 10.6265 24.4021 8.84288 23.3177 7.75898C23.0065 7.45257 23.4916 7.02724 23.757 7.31994C25.0976 8.78343 25.061 10.8643 23.757 12.1906L16.8842 19.0599C16.8156 19.0599 16.7332 19.101 16.6646 19.101V19.1056Z"
                      :fill="this.inWishlist ? '#397e33' : 'black'"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1131_5945">
                    <rect width="27" height="27" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div @click="addToCompare()" class="add-to-sravnenie">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
              >
                <g clip-path="url(#clip0_1131_5951)">
                  <path
                      d="M13.5 27C6.05475 27 0 20.9452 0 13.5C0 6.05475 6.05475 0 13.5 0C20.9452 0 27 6.05475 27 13.5C27 20.9452 20.9452 27 13.5 27ZM13.5 0.9585C6.588 0.9585 0.9585 6.588 0.9585 13.5C0.9585 20.412 6.58125 26.0415 13.5 26.0415C20.4188 26.0415 26.0415 20.412 26.0415 13.5C26.0415 6.588 20.4188 0.9585 13.5 0.9585Z"
                      :fill="this.inCompare ? '#397e33' : 'black'"
                  />
                  <path
                      d="M13.4999 24.5902C7.38445 24.5902 2.4097 19.6154 2.4097 13.4999C2.4097 7.38442 7.38445 2.40967 13.4999 2.40967C19.6154 2.40967 24.5902 7.38442 24.5902 13.4999C24.5902 19.6154 19.6154 24.5902 13.4999 24.5902ZM13.4999 3.36817C7.91095 3.36817 3.3682 7.91092 3.3682 13.4999C3.3682 19.0889 7.91095 23.6317 13.4999 23.6317C19.0889 23.6317 23.6384 19.0889 23.6384 13.4999C23.6384 7.91092 19.0957 3.36817 13.4999 3.36817Z"
                      :fill="this.inCompare ? '#397e33' : 'black'"
                  />
                  <path
                      d="M9.3757 12.1096C9.2542 12.1096 9.1327 12.0623 9.03145 11.9678L6.84445 9.78084C6.7567 9.69309 6.7027 9.57159 6.7027 9.44334C6.7027 9.31509 6.7567 9.19359 6.84445 9.10584L9.0517 6.89859C9.2407 6.70959 9.54445 6.70959 9.73345 6.89859C9.92245 7.08759 9.92245 7.39134 9.73345 7.58034L7.8637 9.45009L9.7132 11.2996C9.9022 11.4886 9.9022 11.7856 9.7132 11.9813C9.6187 12.0758 9.4972 12.1231 9.3757 12.1231V12.1096Z"
                      :fill="this.inCompare ? '#397e33' : 'black'"
                  />
                  <path
                      d="M19.8179 11.7448C19.5479 11.7448 19.3387 11.5288 19.3387 11.2655V11.063C19.3387 10.4285 18.8257 9.91553 18.1979 9.91553H7.18195C6.9187 9.91553 6.7027 9.69953 6.7027 9.43628C6.7027 9.17303 6.9187 8.95703 7.18195 8.95703H18.1979C19.3589 8.95703 20.2972 9.90203 20.2972 11.0563V11.2588C20.2972 11.522 20.0812 11.738 19.8179 11.738V11.7448Z"
                      :fill="this.inCompare ? '#397e33' : 'black'"
                  />
                  <path
                      d="M17.6108 20.2433C17.4893 20.2433 17.3678 20.196 17.2733 20.1083C17.0843 19.9193 17.0843 19.6155 17.2733 19.4265L19.143 17.5568L17.2935 15.7073C17.1045 15.5183 17.1045 15.2213 17.2935 15.0255C17.4825 14.8365 17.7795 14.8365 17.9752 15.0255L20.1623 17.2125C20.3513 17.4015 20.3513 17.6985 20.1623 17.8875L17.955 20.0948C17.8605 20.1893 17.739 20.2298 17.6108 20.2298V20.2433Z"
                      :fill="this.inCompare ? '#397e33' : 'black'"
                  />
                  <path
                      d="M19.818 18.036H8.80872C7.64772 18.036 6.70947 17.091 6.70947 15.9368C6.70947 15.6735 6.92547 15.4575 7.18872 15.4575C7.45197 15.4575 7.66797 15.6735 7.66797 15.9368C7.66797 16.5713 8.18097 17.0843 8.80872 17.0843H19.818C20.0812 17.0843 20.2972 17.3003 20.2972 17.5635C20.2972 17.8268 20.0812 18.0428 19.818 18.0428V18.036Z"
                      :fill="this.inCompare ? '#397e33' : 'black'"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1131_5951">
                    <rect width="27" height="27" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <a href="/compare" style="color: #1F1F1F">Перейти к сравнению</a>
            </div>
          </div>
        </div>
      </div>
      <div class="product__properties">
        <ul class="nav__properties">
          <li class="head__properties">
            <a class="characteristics" @click="changeTab('characteristics')"
            >Характеристики</a
            >
          </li>
          <li class="head__properties">
            <a class="descriptions" @click="changeTab('descriptions')"
            >Описание</a
            >
          </li>
          <li class="head__properties">
            <a class="delivery" @click="changeTab('delivery')">Доставка</a>
          </li>
          <li class="head__properties">
            <a class="payment" @click="changeTab('payment')">Оплата</a>
          </li>
          <li class="head__properties">
            <a class="guarantee" @click="changeTab('guarantee')">Гарантия</a>
          </li>
        </ul>
        <div class="product__properties-text characteristics">
          <div class="characteristic__item hoverOne">
            <div class="attributes">
              <div class="attributes__item" v-for="(item, index) in evenFilteredAttribute" :key="index">
                <p>{{ item.attrGroup }}</p>
                <span class="line-item"></span>
                <p>{{ item.attr.join(', ') }}</p>
              </div>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="232"
                height="232"
                viewBox="0 0 232 232"
                fill="none"
            >
              <g clip-path="url(#clip0_1131_15185)">
                <path
                    d="M44.7798 122.984C44.7798 81.1061 78.7594 47.0469 120.505 47.0469C162.251 47.0469 196.257 81.1061 196.257 122.984C196.257 164.862 162.277 198.948 120.505 198.948C78.7329 198.948 44.7798 164.889 44.7798 122.984ZM46.8737 122.984C46.8737 163.696 79.8992 196.828 120.532 196.828C161.164 196.828 194.19 163.696 194.19 122.984C194.19 82.2723 161.164 49.1408 120.532 49.1408C79.8992 49.1408 46.8737 82.2458 46.8737 122.984Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M49.2328 122.985C49.2328 83.5448 81.2246 51.5 120.532 51.5C159.839 51.5 191.857 83.5713 191.857 122.985C191.857 162.398 159.865 194.496 120.532 194.496C81.1981 194.496 49.2328 162.424 49.2328 122.985ZM51.3267 122.985C51.3267 161.258 82.3643 192.402 120.532 192.402C158.699 192.402 189.737 161.285 189.737 122.985C189.737 84.6845 158.699 53.5939 120.532 53.5939C82.3643 53.5939 51.3002 84.711 51.3002 122.985H51.3267Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M200.79 97.8305V97.7775C200.79 97.7775 200.79 97.6715 200.763 97.6185C200.763 97.5655 200.737 97.5125 200.737 97.4594V97.3799C201.24 97.2209 201.744 97.0884 202.274 96.9293C202.433 96.8763 202.539 96.8233 202.671 96.7703C205.295 105.013 206.727 113.813 206.727 122.957C206.727 170.587 168.082 209.338 120.585 209.338C73.0875 209.338 34.39 170.587 34.39 122.957C34.39 75.3276 73.0345 36.6035 120.532 36.6035C145.738 36.6035 168.453 47.4971 184.224 64.8581C183.694 65.3617 183.19 65.8653 182.74 66.3423C167.367 49.3525 145.182 38.6974 120.558 38.6974C74.2273 38.6974 36.5104 76.4938 36.5104 122.957C36.5104 169.421 74.2008 207.217 120.558 207.217C166.916 207.217 204.606 169.421 204.606 122.957C204.606 114.237 203.308 105.835 200.843 97.91"
                    fill="#1F1F1F"
                />
                <mask
                    id="mask0_1131_15185"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="29"
                    y="30"
                    width="183"
                    height="185"
                >
                  <path
                      d="M211.073 30.4014H29.2213V214.162H211.073V30.4014Z"
                      fill="white"
                  />
                </mask>
                <g mask="url(#mask0_1131_15185)">
                  <path
                      d="M120.532 32.3096C146.931 32.3096 170.68 43.7068 187.219 61.8364C186.795 62.1544 186.397 62.5255 186.053 62.9231C185.973 63.0291 185.894 63.1086 185.814 63.1881C185.788 63.2146 185.788 63.2941 185.735 63.2941C169.593 45.5622 146.295 34.377 120.532 34.377C71.8418 34.4035 32.19 74.1348 32.19 122.984C32.19 171.833 71.8153 211.564 120.505 211.564C169.195 211.564 208.847 171.833 208.847 122.984C208.847 113.813 207.442 104.934 204.845 96.5847C205.534 96.4522 206.223 96.3196 206.912 96.1606C209.51 104.642 210.968 113.654 210.968 122.984C210.968 172.973 170.415 213.658 120.532 213.658C70.6491 213.658 30.0961 172.973 30.0961 122.984C30.0961 72.9951 70.6491 32.3096 120.532 32.3096Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M182.077 62.6845L182.209 62.817C182.209 62.817 182.262 62.8965 182.289 62.923C182.368 63.029 182.474 63.1085 182.554 63.1881C182.739 63.3471 182.898 63.5326 183.084 63.6917C183.27 63.8772 183.429 64.0627 183.588 64.2218C183.667 64.3013 183.747 64.3808 183.826 64.4338C183.879 64.4868 183.932 64.5663 183.985 64.6193C184.25 64.9109 184.515 65.1229 184.913 65.1229C185.522 65.1229 186.026 64.6458 186.026 64.0097C186.026 63.7182 185.894 63.4531 185.708 63.2411C185.708 63.2411 185.682 63.2411 185.682 63.1881C185.682 63.1616 185.629 63.1351 185.629 63.1085C185.575 63.029 185.549 63.0025 185.496 62.9495C185.469 62.9495 185.443 62.8965 185.416 62.8965C185.416 62.87 185.39 62.87 185.363 62.8435C185.363 62.8435 185.363 62.8435 185.363 62.817C185.337 62.7905 185.284 62.7375 185.257 62.711C185.231 62.6845 185.178 62.658 185.151 62.605C185.151 62.605 185.151 62.605 185.125 62.605C184.966 62.4194 184.807 62.2339 184.648 62.0748C184.462 61.8628 184.277 61.6243 184.118 61.4387C183.985 61.3062 183.879 61.2002 183.72 61.0676C184.091 60.9086 184.515 60.8291 184.913 60.8291C186.689 60.8291 188.093 62.2604 188.093 64.0097C188.093 65.7591 186.662 67.1903 184.913 67.1903C183.164 67.1903 181.732 65.7591 181.732 64.0097C181.732 63.5061 181.865 63.0555 182.077 62.6315V62.6845Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M205.746 99.5002C205.746 99.5002 205.693 99.3676 205.693 99.3146C205.693 99.2616 205.693 99.2351 205.667 99.1821C205.64 99.0496 205.587 98.9436 205.56 98.811C205.481 98.5725 205.401 98.3604 205.322 98.0954C205.242 97.8568 205.163 97.6183 205.11 97.3798C205.083 97.2737 205.057 97.1942 205.004 97.0882C204.951 97.0087 204.951 96.9292 204.898 96.8496C204.792 96.4521 204.659 96.1605 204.315 95.975C203.785 95.6834 203.096 95.8955 202.83 96.4521C202.698 96.7171 202.671 96.9822 202.751 97.2472V97.3002C202.751 97.3797 202.777 97.3798 202.777 97.4063C202.777 97.4858 202.804 97.5388 202.83 97.6183C202.83 97.6448 202.83 97.6713 202.83 97.6978C202.83 97.6978 202.83 97.7508 202.857 97.7773V97.8303C202.857 97.8303 202.857 97.9364 202.91 97.9629C202.91 98.0159 202.936 98.0424 202.963 98.0954C202.963 98.0954 202.963 98.0954 202.963 98.1219C203.043 98.3604 203.096 98.599 203.149 98.811C203.228 99.0761 203.281 99.3676 203.334 99.6327C203.387 99.8182 203.44 99.9773 203.52 100.136C203.122 100.11 202.724 99.9773 202.327 99.7917C200.763 98.9701 200.153 97.0617 200.949 95.4714C201.744 93.8811 203.679 93.2714 205.269 94.0666C206.833 94.8618 207.442 96.7966 206.647 98.3869C206.409 98.8375 206.091 99.1821 205.72 99.4737L205.746 99.5002Z"
                    fill="#1F1F1F"
                />
                <mask
                    id="mask1_1131_15185"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="156"
                    y="0"
                    width="19"
                    height="52"
                >
                  <path
                      d="M174.125 0H156.446V51.6056H174.125V0Z"
                      fill="white"
                  />
                </mask>
                <g mask="url(#mask1_1131_15185)">
                  <path
                      d="M170.653 49.5117H172.747V22.1318C172.747 20.0379 171.05 18.3681 169.009 18.3681H163.682V0H157.109V41.9842H159.202V2.12042H161.588V20.4885H169.009C169.911 20.4885 170.653 21.2307 170.653 22.1583V49.5117Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M166.651 46.8613H168.745V21.9199H161.588V43.8662H163.682V24.0138H166.624V46.8613H166.651Z"
                    fill="#1F1F1F"
                />
                <mask
                    id="mask2_1131_15185"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="64"
                    y="0"
                    width="22"
                    height="52"
                >
                  <path
                      d="M85.7836 0H64.5794V51.6056H85.7836V0Z"
                      fill="white"
                  />
                </mask>
                <g mask="url(#mask2_1131_15185)">
                  <path
                      d="M68.0516 49.5117H70.1455V22.1318C70.1455 21.2307 70.8877 20.462 71.8154 20.462H79.2368V2.12042H81.6223V41.9842H83.7162V0H77.1694V18.3681H71.8419C69.7745 18.3681 68.0781 20.0644 68.0781 22.1318V49.4852L68.0516 49.5117Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M72.0537 46.8613H74.1476V24.0138H77.1162V43.8662H79.2101V21.9199H72.0537V46.8613Z"
                    fill="#1F1F1F"
                />
                <mask
                    id="mask3_1131_15185"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="156"
                    y="192"
                    width="19"
                    height="40"
                >
                  <path
                      d="M174.125 192.958H156.446V232H174.125V192.958Z"
                      fill="white"
                  />
                </mask>
                <g mask="url(#mask3_1131_15185)">
                  <path
                      d="M157.109 232.028H163.682V227.469H169.009C171.077 227.469 172.747 225.772 172.747 223.705V196.352H170.653V223.705C170.653 224.606 169.911 225.375 169.009 225.375H161.588V229.934H159.202V203.879H157.109V232.028Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M161.615 223.942H168.771V199.001H166.677V221.848H163.735V201.996H161.641V223.942H161.615Z"
                    fill="#1F1F1F"
                />
                <mask
                    id="mask4_1131_15185"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="64"
                    y="192"
                    width="22"
                    height="40"
                >
                  <path
                      d="M85.7836 192.958H64.5794V232H85.7836V192.958Z"
                      fill="white"
                  />
                </mask>
                <g mask="url(#mask4_1131_15185)">
                  <path
                      d="M77.1429 232.028H83.7162V203.879H81.6223V229.934H79.2368V225.375H71.8154C70.9142 225.375 70.1455 224.633 70.1455 223.705V196.352H68.0516V223.705C68.0516 225.799 69.748 227.469 71.8154 227.469H77.1429V232.028Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M72.0537 223.942H79.2101V201.996H77.1162V221.848H74.1476V199.001H72.0537V223.942Z"
                    fill="#1F1F1F"
                />
                <mask
                    id="mask5_1131_15185"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="17"
                    y="108"
                    width="16"
                    height="29"
                >
                  <path
                      d="M32.773 108.142H17.4V136.423H32.773V108.142Z"
                      fill="white"
                  />
                </mask>
                <g mask="url(#mask5_1131_15185)">
                  <path
                      d="M31.8455 136.263L32.084 134.17L21.641 132.897C20.4747 132.765 19.6001 131.758 19.6001 130.591V115.324C19.6001 114.158 20.4747 113.151 21.641 113.045L32.084 111.773L31.8455 109.679L21.4024 110.951C19.2025 111.216 17.5327 113.098 17.5327 115.351V130.618C17.5327 132.844 19.2025 134.726 21.4024 135.018L31.8455 136.29V136.263Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M31.2886 131.36L31.6066 129.266L26.5442 128.497C25.4309 128.312 24.6358 127.384 24.6358 126.271V119.645C24.6358 118.531 25.4309 117.577 26.5442 117.418L31.6332 116.623L31.3151 114.556L26.2261 115.324C24.0792 115.669 22.5419 117.471 22.5419 119.645V126.271C22.5419 128.444 24.0792 130.273 26.2261 130.591L31.2886 131.386V131.36Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M120.532 163.007C98.5324 163.007 80.6149 145.036 80.6149 122.957C80.6149 100.879 98.5324 82.9346 120.532 82.9346C142.531 82.9346 160.475 100.905 160.475 122.957C160.475 145.01 142.558 163.007 120.532 163.007ZM120.532 85.0285C99.6721 85.0285 82.7088 102.045 82.7088 122.957C82.7088 143.87 99.6721 160.886 120.532 160.886C141.391 160.886 158.381 143.87 158.381 122.957C158.381 102.045 141.418 85.0285 120.532 85.0285Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M120.532 168.573C95.4578 168.573 75.0488 148.111 75.0488 122.957C75.0488 97.8037 95.4313 77.3682 120.532 77.3682C145.632 77.3682 166.015 97.8037 166.015 122.957C166.015 148.111 145.606 168.573 120.532 168.573ZM120.532 79.4621C96.6241 79.4621 77.1427 98.9699 77.1427 122.957C77.1427 146.944 96.6241 166.479 120.532 166.479C144.439 166.479 163.921 146.971 163.921 122.957C163.921 98.9434 144.466 79.4621 120.532 79.4621Z"
                    fill="#1F1F1F"
                />

                <g class="st0">
                  <path
                      d="M120.532 70.6893C117.749 70.6893 115.496 68.4364 115.496 65.6268C115.496 62.8173 117.749 60.5908 120.532 60.5908C123.315 60.5908 125.568 62.8438 125.568 65.6268C125.568 68.4099 123.315 70.6893 120.532 70.6893ZM120.532 62.7112C118.941 62.7112 117.616 64.01 117.616 65.6533C117.616 67.2966 118.915 68.5954 120.532 68.5954C122.149 68.5954 123.474 67.2966 123.474 65.6533C123.474 64.01 122.175 62.7112 120.532 62.7112Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M120.532 74.3467C115.761 74.3467 111.838 70.4504 111.838 65.6265C111.838 60.8025 115.734 56.9062 120.532 56.9062C125.329 56.9062 129.225 60.8025 129.225 65.6265C129.225 70.4504 125.329 74.3467 120.532 74.3467ZM120.532 59.0267C116.901 59.0267 113.958 61.9687 113.958 65.6265C113.958 69.2842 116.901 72.2263 120.532 72.2263C124.163 72.2263 127.132 69.2577 127.132 65.6265C127.132 61.9953 124.189 59.0267 120.532 59.0267Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M161.244 168.096C158.487 168.096 156.208 165.843 156.208 163.06C156.208 160.277 158.461 157.997 161.244 157.997C164.027 157.997 166.28 160.25 166.28 163.06C166.28 165.869 164.027 168.096 161.244 168.096ZM161.244 160.117C159.627 160.117 158.302 161.416 158.302 163.06C158.302 164.703 159.6 166.002 161.244 166.002C162.887 166.002 164.159 164.703 164.159 163.06C164.159 161.416 162.861 160.117 161.244 160.117Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M161.244 171.754C156.446 171.754 152.55 167.857 152.55 163.06C152.55 158.263 156.446 154.34 161.244 154.34C166.041 154.34 169.937 158.236 169.937 163.06C169.937 167.884 166.041 171.754 161.244 171.754ZM161.244 156.434C157.612 156.434 154.644 159.376 154.644 163.034C154.644 166.691 157.612 169.633 161.244 169.633C164.875 169.633 167.817 166.691 167.817 163.034C167.817 159.376 164.875 156.434 161.244 156.434Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M78.9187 168.096C76.1357 168.096 73.8828 165.843 73.8828 163.06C73.8828 160.277 76.1357 157.997 78.9187 157.997C81.7018 157.997 83.9547 160.25 83.9547 163.06C83.9547 165.869 81.7018 168.096 78.9187 168.096ZM78.9187 160.117C77.3019 160.117 76.0032 161.416 76.0032 163.06C76.0032 164.703 77.3019 166.002 78.9187 166.002C80.5356 166.002 81.8608 164.703 81.8608 163.06C81.8608 161.416 80.5621 160.117 78.9187 160.117Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M78.9187 171.754C74.1213 171.754 70.225 167.857 70.225 163.06C70.225 158.263 74.1213 154.34 78.9187 154.34C83.7162 154.34 87.6124 158.236 87.6124 163.06C87.6124 167.884 83.7162 171.754 78.9187 171.754ZM78.9187 156.434C75.2875 156.434 72.3455 159.376 72.3455 163.034C72.3455 166.691 75.2875 169.633 78.9187 169.633C82.5499 169.633 85.5185 166.691 85.5185 163.034C85.5185 159.376 82.5499 156.434 78.9187 156.434Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M120.532 185.271C117.749 185.271 115.496 183.018 115.496 180.235C115.496 177.452 117.749 175.199 120.532 175.199C123.315 175.199 125.568 177.452 125.568 180.235C125.568 183.018 123.315 185.271 120.532 185.271ZM120.532 177.293C118.941 177.293 117.616 178.592 117.616 180.235C117.616 181.879 118.915 183.177 120.532 183.177C122.149 183.177 123.474 181.879 123.474 180.235C123.474 178.592 122.175 177.293 120.532 177.293Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M120.532 188.929C115.761 188.929 111.838 185.032 111.838 180.208C111.838 175.385 115.734 171.488 120.532 171.488C125.329 171.488 129.225 175.385 129.225 180.208C129.225 185.032 125.329 188.929 120.532 188.929ZM120.532 173.609C116.901 173.609 113.958 176.577 113.958 180.208C113.958 183.84 116.901 186.808 120.532 186.808C124.163 186.808 127.132 183.84 127.132 180.208C127.132 176.577 124.189 173.609 120.532 173.609Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M178.552 127.994C175.769 127.994 173.516 125.741 173.516 122.958C173.516 120.175 175.769 117.896 178.552 117.896C181.335 117.896 183.588 120.148 183.588 122.958C183.588 125.768 181.335 127.994 178.552 127.994ZM178.552 120.016C176.935 120.016 175.636 121.315 175.636 122.958C175.636 124.601 176.935 125.9 178.552 125.9C180.168 125.9 181.494 124.601 181.494 122.958C181.494 121.315 180.195 120.016 178.552 120.016Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M178.552 131.678C173.754 131.678 169.858 127.781 169.858 122.957C169.858 118.133 173.754 114.264 178.552 114.264C183.349 114.264 187.245 118.16 187.245 122.957C187.245 127.755 183.349 131.678 178.552 131.678ZM178.552 116.358C174.92 116.358 171.978 119.3 171.978 122.957C171.978 126.615 174.92 129.557 178.552 129.557C182.183 129.557 185.125 126.615 185.125 122.957C185.125 119.3 182.183 116.358 178.552 116.358Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M62.5119 127.994C59.7288 127.994 57.4759 125.741 57.4759 122.958C57.4759 120.175 59.7288 117.896 62.5119 117.896C65.2949 117.896 67.5479 120.148 67.5479 122.958C67.5479 125.768 65.2949 127.994 62.5119 127.994ZM62.5119 120.016C60.8951 120.016 59.5698 121.315 59.5698 122.958C59.5698 124.601 60.8686 125.9 62.5119 125.9C64.1552 125.9 65.454 124.601 65.454 122.958C65.454 121.315 64.1552 120.016 62.5119 120.016Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M62.5118 131.678C57.7144 131.678 53.8181 127.781 53.8181 122.957C53.8181 118.133 57.7144 114.264 62.5118 114.264C67.3093 114.264 71.2055 118.16 71.2055 122.957C71.2055 127.755 67.3093 131.678 62.5118 131.678ZM62.5118 116.358C58.8806 116.358 55.9385 119.3 55.9385 122.957C55.9385 126.615 58.8806 129.557 62.5118 129.557C66.143 129.557 69.0851 126.615 69.0851 122.957C69.0851 119.3 66.143 116.358 62.5118 116.358Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M77.7789 89.031C74.9958 89.031 72.7429 86.778 72.7429 83.995C72.7429 81.2119 74.9958 78.959 77.7789 78.959C80.5619 78.959 82.8148 81.2119 82.8148 83.995C82.8148 86.778 80.5619 89.031 77.7789 89.031ZM77.7789 81.0529C76.162 81.0529 74.8368 82.3516 74.8368 83.995C74.8368 85.6383 76.1355 86.937 77.7789 86.937C79.4222 86.937 80.6944 85.6383 80.6944 83.995C80.6944 82.3516 79.3957 81.0529 77.7789 81.0529Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M77.7789 92.6885C72.9814 92.6885 69.0851 88.7922 69.0851 83.9683C69.0851 79.1443 72.9814 75.248 77.7789 75.248C82.5763 75.248 86.4726 79.1443 86.4726 83.9683C86.4726 88.7922 82.5763 92.6885 77.7789 92.6885ZM77.7789 77.3685C74.1211 77.3685 71.1791 80.337 71.1791 83.9683C71.1791 87.5995 74.1211 90.568 77.7789 90.568C81.4366 90.568 84.3521 87.5995 84.3521 83.9683C84.3521 80.337 81.4101 77.3685 77.7789 77.3685Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M162.092 89.031C159.309 89.031 157.056 86.778 157.056 83.995C157.056 81.2119 159.309 78.959 162.092 78.959C164.875 78.959 167.128 81.2119 167.128 83.995C167.128 86.778 164.875 89.031 162.092 89.031ZM162.092 81.0529C160.475 81.0529 159.15 82.3516 159.15 83.995C159.15 85.6383 160.448 86.937 162.092 86.937C163.735 86.937 165.007 85.6383 165.007 83.995C165.007 82.3516 163.709 81.0529 162.092 81.0529Z"
                      fill="#1F1F1F"
                  />
                  <path
                      d="M162.092 92.6885C157.295 92.6885 153.398 88.7922 153.398 83.9683C153.398 79.1443 157.295 75.248 162.092 75.248C166.889 75.248 170.786 79.1443 170.786 83.9683C170.786 88.7922 166.889 92.6885 162.092 92.6885ZM162.092 77.3685C158.434 77.3685 155.492 80.337 155.492 83.9683C155.492 87.5995 158.434 90.568 162.092 90.568C165.75 90.568 168.665 87.5995 168.665 83.9683C168.665 80.337 165.723 77.3685 162.092 77.3685Z"
                      fill="#1F1F1F"
                  />
                </g>
                <path
                    d="M158.858 229.8H79.3958V231.92H158.858V229.8Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M158.858 0H79.3958V2.12042H158.858V0Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M196.681 82.6962C194.72 78.9855 192.493 75.4073 190.028 72.0146C189.525 72.3857 188.995 72.7568 188.491 73.0749C188.438 73.1279 188.358 73.1544 188.305 73.2074C190.717 76.494 192.891 79.9927 194.799 83.6239C195.435 83.2794 196.045 82.9613 196.681 82.6697V82.6962Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M199.862 82.776C199.941 82.67 199.994 82.511 199.862 82.776C199.888 82.723 199.941 82.67 199.968 82.617C200.047 82.511 200.127 82.3785 200.206 82.2724C200.365 82.0604 200.498 81.8219 200.604 81.5833C200.63 81.5303 200.657 81.4773 200.683 81.4243C200.498 81.6893 200.604 81.5833 200.683 81.4773C200.683 81.4243 200.71 81.3713 200.736 81.3183C199.279 78.5352 197.741 75.8052 196.045 73.2077C195.223 73.1812 194.402 73.2077 193.606 73.2607C193.606 73.2607 193.606 73.2607 193.58 73.2607C195.753 76.4678 197.715 79.834 199.464 83.3327C199.623 83.1471 199.756 82.9881 199.915 82.8025L199.862 82.776Z"
                    fill="#1F1F1F"
                />
                <path
                    d="M198.828 87.2017C198.51 86.4861 198.192 85.744 197.847 85.0283C197.847 85.0283 197.847 85.0283 197.821 85.0283C197.821 85.0283 197.821 85.0283 197.741 85.0548C197.609 85.1343 197.45 85.1873 197.317 85.2669C196.867 85.5054 196.443 85.744 195.992 85.9825C196.31 86.6451 196.602 87.3078 196.893 87.9704L198.855 87.1752L198.828 87.2017Z"
                    fill="#1F1F1F"
                />
              </g>
              <defs>
                <clipPath id="clip0_1131_15185">
                  <rect width="232" height="232" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="characteristic__item hoverTwo">
            <div class="attributes">
              <div class="attributes__item" v-for="(item, index) in oddFilteredAttribute" :key="index">
                <p>{{ item.attrGroup }}</p>
                <span class="line-item"></span>
                <p>{{ item.attr.join(', ') }}</p>
              </div>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="232"
                height="232"
                viewBox="0 0 232 232"
                fill="none"
            >
              <path
                  d="M107.644 198.894C66.0242 198.894 32.1765 164.842 32.1765 122.967C32.1765 81.0915 66.0242 47.0137 107.644 47.0137C149.263 47.0137 183.111 81.0659 183.111 122.941C183.111 164.816 149.263 198.894 107.644 198.894ZM107.644 49.11C67.1746 49.11 34.2728 82.2163 34.2728 122.941C34.2728 163.666 67.2002 196.797 107.644 196.797C148.087 196.797 181.014 163.666 181.014 122.941C181.014 82.2163 148.087 49.11 107.644 49.11Z"
                  fill="black"
              />
              <path
                  d="M107.644 194.471C68.4528 194.471 36.5991 162.387 36.5991 122.966C36.5991 83.5457 68.4528 51.4619 107.644 51.4619C146.834 51.4619 178.688 83.5457 178.688 122.966C178.688 162.387 146.834 194.471 107.644 194.471ZM107.644 53.5838C69.6032 53.5838 38.6954 84.7216 38.6954 122.992C38.6954 161.262 69.6288 192.4 107.644 192.4C145.658 192.4 176.592 161.262 176.592 122.992C176.592 84.7216 145.658 53.5838 107.644 53.5838Z"
                  fill="black"
              />
              <path
                  d="M107.644 130.712C103.4 130.712 99.9486 127.235 99.9486 122.966C99.9486 118.697 103.4 115.22 107.644 115.22C111.887 115.22 115.339 118.697 115.339 122.966C115.339 127.235 111.887 130.712 107.644 130.712ZM107.644 117.342C104.55 117.342 102.045 119.847 102.045 122.966C102.045 126.085 104.55 128.59 107.644 128.59C110.737 128.59 113.242 126.085 113.242 122.966C113.242 119.847 110.737 117.342 107.644 117.342Z"
                  fill="black"
              />
              <path
                  d="M27.6769 97.836C27.6769 97.836 27.7025 97.8359 27.7025 97.8104C27.7025 97.8104 27.7025 97.7848 27.7025 97.7593C27.7025 97.7593 27.7025 97.657 27.7281 97.6059C27.7281 97.5547 27.7281 97.5036 27.7536 97.4525V97.4013C27.2423 97.2224 26.7311 97.069 26.2198 96.9156C26.0664 96.8645 25.9641 96.8134 25.8363 96.7622C23.2031 105.02 21.7971 113.814 21.7971 122.941C21.7971 170.568 60.2976 209.298 107.618 209.298C154.938 209.298 193.439 170.568 193.439 122.941C193.439 75.3134 154.964 36.6084 107.643 36.6084C82.5389 36.6084 59.9141 47.5245 44.1918 64.8574C44.7286 65.3432 45.2144 65.8545 45.6745 66.3402C60.9878 49.3652 83.1013 38.7047 107.618 38.7047C153.788 38.7047 191.368 76.4894 191.368 122.966C191.368 169.443 153.813 207.227 107.618 207.227C61.4224 207.227 23.8934 169.443 23.8934 122.966C23.8934 114.249 25.1972 105.838 27.6514 97.9382"
                  fill="black"
              />
              <mask
                  id="mask0_1131_15121"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="17"
                  y="29"
                  width="184"
                  height="185"
              >
                <path
                    d="M200.444 29.9102H17.4V213.976H200.444V29.9102Z"
                    fill="white"
                />
              </mask>
              <g mask="url(#mask0_1131_15121)">
                <path
                    d="M107.644 32.3135C81.3631 32.3135 57.6902 43.7153 41.2009 61.8407C41.6355 62.1731 42.019 62.531 42.3769 62.94C42.4536 63.0423 42.5303 63.119 42.607 63.2212C42.6581 63.2468 42.6581 63.3235 42.6837 63.3235C58.7895 45.6071 81.9511 34.4098 107.669 34.4098C156.191 34.4098 195.689 74.1374 195.689 122.966C195.689 171.795 156.191 211.522 107.669 211.522C59.1474 211.522 19.6498 171.795 19.6498 122.966C19.6498 113.788 21.0559 104.917 23.6379 96.5577C22.9477 96.4554 22.2574 96.302 21.5928 96.1486C18.9596 104.611 17.5535 113.635 17.5535 122.966C17.5535 172.971 57.9458 213.619 107.644 213.619C157.342 213.619 197.734 172.945 197.734 122.966C197.734 72.987 157.316 32.3135 107.644 32.3135Z"
                    fill="black"
                />
              </g>
              <path
                  d="M107.643 75.3899C105.726 75.3899 104.167 73.8304 104.167 71.8875V58.6449C104.167 56.702 105.726 55.1426 107.643 55.1426C109.561 55.1426 111.12 56.702 111.12 58.6449V71.8875C111.12 73.8304 109.561 75.3899 107.643 75.3899ZM107.643 57.2389C106.876 57.2389 106.263 57.878 106.263 58.6449V71.8875C106.263 72.6544 106.876 73.2935 107.643 73.2935C108.41 73.2935 109.024 72.68 109.024 71.8875V58.6449C109.024 57.878 108.41 57.2389 107.643 57.2389Z"
                  fill="black"
              />
              <path
                  d="M107.643 190.789C105.726 190.789 104.167 189.23 104.167 187.287V174.044C104.167 172.101 105.726 170.542 107.643 170.542C109.561 170.542 111.12 172.101 111.12 174.044V187.287C111.12 189.23 109.561 190.789 107.643 190.789ZM107.643 172.638C106.876 172.638 106.263 173.277 106.263 174.044V187.287C106.263 188.054 106.876 188.693 107.643 188.693C108.41 188.693 109.024 188.079 109.024 187.287V174.044C109.024 173.277 108.41 172.638 107.643 172.638Z"
                  fill="black"
              />
              <path
                  d="M170.61 126.469H157.444C155.501 126.469 153.967 124.909 153.967 122.966C153.967 121.023 155.501 119.464 157.444 119.464H170.61C172.553 119.464 174.086 121.023 174.086 122.966C174.086 124.909 172.553 126.469 170.61 126.469ZM157.444 121.56C156.677 121.56 156.038 122.174 156.038 122.966C156.038 123.759 156.651 124.372 157.444 124.372H170.61C171.377 124.372 172.016 123.759 172.016 122.966C172.016 122.174 171.402 121.56 170.61 121.56H157.444Z"
                  fill="black"
              />
              <path
                  d="M134.154 83.5715C133.566 83.5715 132.953 83.4181 132.416 83.1114C130.754 82.1399 130.166 79.9925 131.138 78.3308L137.708 66.8522C138.193 66.0597 138.909 65.4717 139.83 65.2161C140.724 64.986 141.67 65.0882 142.463 65.574C143.281 66.0341 143.843 66.8011 144.099 67.6958C144.329 68.5906 144.201 69.5365 143.741 70.3546L137.171 81.8331C136.711 82.6256 135.969 83.2136 135.075 83.4693C134.768 83.546 134.461 83.5971 134.154 83.5971V83.5715ZM133.464 81.2707C133.796 81.4752 134.154 81.5264 134.512 81.4241C134.87 81.3218 135.177 81.0918 135.356 80.7594L141.926 69.2809C142.309 68.6162 142.079 67.7725 141.415 67.3891C141.082 67.1845 140.724 67.1334 140.366 67.2357C140.009 67.3379 139.702 67.568 139.523 67.9004L132.953 79.3789C132.569 80.0436 132.799 80.8872 133.464 81.2707Z"
                  fill="black"
              />
              <path
                  d="M74.6139 181.714C74.0259 181.714 73.4124 181.56 72.8755 181.228C72.083 180.768 71.495 180.026 71.2394 179.106C70.9837 178.186 71.1371 177.265 71.5973 176.447L78.1674 164.994C78.6276 164.176 79.369 163.614 80.2893 163.358C81.1841 163.103 82.1299 163.23 82.9225 163.716C84.5842 164.688 85.1722 166.81 84.2007 168.497L77.6306 179.95C77.1704 180.768 76.429 181.33 75.5087 181.586C75.2275 181.688 74.9207 181.714 74.6139 181.714ZM81.2096 165.352C81.0818 165.352 80.9795 165.352 80.8261 165.403C80.4682 165.506 80.1615 165.736 79.9825 166.068L73.4124 177.521C73.2334 177.853 73.1823 178.237 73.2846 178.595C73.3868 178.953 73.6169 179.26 73.9237 179.439C74.256 179.617 74.6395 179.669 74.9974 179.566C75.3553 179.464 75.6621 179.234 75.841 178.902L82.4112 167.423C82.7946 166.758 82.5646 165.915 81.8999 165.531C81.6953 165.403 81.4397 165.352 81.2096 165.352Z"
                  fill="black"
              />
              <path
                  d="M139.574 181.714C138.372 181.714 137.196 181.101 136.557 179.976L129.987 168.523C129.016 166.835 129.603 164.714 131.265 163.742C132.058 163.282 133.004 163.154 133.898 163.384C134.793 163.614 135.56 164.202 135.995 165.02L142.565 176.499C143.536 178.161 142.948 180.308 141.312 181.28C140.775 181.586 140.162 181.74 139.574 181.74V181.714ZM132.978 165.353C132.748 165.353 132.492 165.429 132.288 165.532C131.623 165.941 131.419 166.784 131.777 167.449L138.347 178.928C138.73 179.592 139.574 179.822 140.238 179.439C140.929 179.055 141.133 178.212 140.75 177.547L134.18 166.069C134.001 165.736 133.694 165.506 133.336 165.404C133.208 165.378 133.106 165.353 132.978 165.353Z"
                  fill="black"
              />
              <path
                  d="M82.3343 81.986C82.0531 81.986 81.7463 81.9349 81.4395 81.8582C80.5448 81.6281 79.7778 81.0401 79.3432 80.222L72.7731 68.7435C71.8016 67.0818 72.3896 64.9343 74.0513 63.9629C74.8438 63.5027 75.7897 63.3749 76.6845 63.605C77.5792 63.835 78.3462 64.423 78.7808 65.2411L85.3509 76.7197C85.8111 77.5377 85.9389 78.4836 85.7088 79.3784C85.4787 80.2732 84.8908 81.0401 84.0983 81.5003C83.5614 81.8071 82.9734 81.9604 82.3598 81.9604L82.3343 81.986ZM75.7641 65.6246C75.5341 65.6246 75.2784 65.7013 75.0739 65.8035C74.4092 66.187 74.1791 67.0306 74.5626 67.7209L81.1327 79.1739C81.3117 79.5062 81.6185 79.7363 81.9764 79.8386C82.3343 79.9408 82.7178 79.8897 83.0245 79.7107C83.3569 79.5318 83.587 79.225 83.6892 78.8671C83.7915 78.5092 83.7403 78.1257 83.5614 77.7934L76.9913 66.3404C76.8123 66.008 76.5055 65.778 76.1476 65.6757C76.0454 65.6501 75.9175 65.6246 75.7897 65.6246H75.7641Z"
                  fill="black"
              />
              <path
                  d="M64.158 101.006C63.5445 101.006 62.9565 100.853 62.4196 100.546L51.0178 93.925C50.2253 93.4649 49.6373 92.6979 49.4072 91.8032C49.1771 90.9084 49.2794 89.9625 49.7651 89.1444C50.2253 88.3264 50.9666 87.7384 51.8614 87.5083C52.7562 87.2782 53.7021 87.406 54.4946 87.8662L65.8964 94.4875C66.6889 94.9476 67.2769 95.7146 67.507 96.6093C67.7371 97.5041 67.6348 98.45 67.1491 99.2681C66.6889 100.086 65.9476 100.649 65.0528 100.904C64.746 100.981 64.4393 101.032 64.158 101.032V101.006ZM52.7562 89.4768C52.7562 89.4768 52.5261 89.4768 52.3983 89.5279C52.0404 89.6302 51.7336 89.8602 51.5546 90.167C51.3757 90.4994 51.3245 90.8828 51.4268 91.2407C51.5291 91.5986 51.7591 91.9054 52.0915 92.0844L63.4934 98.7056C63.8257 98.8846 64.1836 98.9357 64.5415 98.8335C64.8994 98.7312 65.2062 98.5011 65.3851 98.1688C65.5641 97.8364 65.6152 97.4785 65.513 97.1206C65.4107 96.7627 65.1806 96.4559 64.8483 96.277L53.4464 89.6557C53.2419 89.5279 52.9862 89.4768 52.7562 89.4768Z"
                  fill="black"
              />
              <path
                  d="M163.247 158.578C162.633 158.578 162.045 158.425 161.508 158.118L150.106 151.497C148.445 150.525 147.882 148.378 148.854 146.716C149.314 145.898 150.055 145.336 150.95 145.08C151.845 144.824 152.791 144.952 153.583 145.438L164.985 152.059C166.647 153.031 167.209 155.152 166.238 156.84C165.778 157.632 165.036 158.22 164.141 158.476C163.835 158.553 163.528 158.604 163.247 158.604V158.578ZM151.845 147.048C151.845 147.048 151.615 147.048 151.487 147.1C151.129 147.202 150.822 147.432 150.643 147.764C150.26 148.429 150.49 149.273 151.155 149.656L162.556 156.277C163.221 156.686 164.065 156.431 164.448 155.766C164.832 155.076 164.602 154.232 163.937 153.849L152.535 147.227C152.331 147.1 152.075 147.048 151.845 147.048Z"
                  fill="black"
              />
              <path
                  d="M51.0176 157.249C49.816 157.249 48.6401 156.635 48.001 155.51C47.5408 154.692 47.413 153.746 47.643 152.852C47.8731 151.957 48.4611 151.19 49.2536 150.73L60.6555 144.108C62.3172 143.137 64.4391 143.725 65.4105 145.387C65.8707 146.205 65.9985 147.151 65.7684 148.045C65.5384 148.94 64.9504 149.707 64.1323 150.167L52.7304 156.789C52.1936 157.121 51.58 157.274 50.992 157.274L51.0176 157.249ZM49.8161 154.437C50.1995 155.101 51.0432 155.331 51.7078 154.948L63.1097 148.327C63.4421 148.148 63.6721 147.841 63.7744 147.483C63.8767 147.125 63.8255 146.742 63.621 146.409C63.2375 145.745 62.3939 145.514 61.7292 145.898L50.3273 152.519C49.995 152.698 49.7649 153.005 49.6627 153.363C49.5604 153.721 49.6115 154.104 49.7905 154.411L49.8161 154.437Z"
                  fill="black"
              />
              <path
                  d="M56.8974 126.469H43.7315C41.8142 126.469 40.2547 124.909 40.2547 122.966C40.2547 121.023 41.8142 119.464 43.7315 119.464H56.8974C58.8147 119.464 60.3742 121.023 60.3742 122.966C60.3742 124.909 58.8147 126.469 56.8974 126.469ZM43.7315 121.56C42.9646 121.56 42.351 122.174 42.351 122.966C42.351 123.759 42.9646 124.372 43.7315 124.372H56.8974C57.6643 124.372 58.2779 123.759 58.2779 122.966C58.2779 122.174 57.6643 121.56 56.8974 121.56H43.7315Z"
                  fill="black"
              />
              <g class="st3">
                <path
                    d="M107.516 129.638C106.953 129.638 106.391 129.536 105.854 129.383L86.4247 176.626C86.348 176.805 86.1435 176.908 85.9646 176.831C85.7856 176.754 85.6833 176.55 85.76 176.371L104.32 128.641C103.655 128.207 103.118 127.619 102.735 126.954L83.8171 175.629C83.3314 176.882 83.9449 178.314 85.1976 178.799C85.4788 178.927 85.7856 178.978 86.0924 178.978C87.0638 178.978 87.9586 178.39 88.3676 177.444L108.027 129.587C107.848 129.613 107.695 129.638 107.516 129.638Z"
                    fill="black"
                />
                <path
                    d="M114.418 111.59C114.086 110.798 113.498 110.21 112.705 109.903C111.938 109.571 111.069 109.596 110.302 109.928C109.535 110.261 108.947 110.874 108.641 111.667L106.57 117.01C107.337 116.652 108.129 116.601 108.922 116.754L110.609 112.434C110.711 112.178 110.89 111.974 111.146 111.871C111.402 111.769 111.683 111.744 111.938 111.871C112.194 111.974 112.399 112.178 112.501 112.434C112.603 112.689 112.603 112.971 112.501 113.226L110.711 117.572C111.325 118.007 111.862 118.569 112.322 119.183L114.444 114.044C114.776 113.252 114.776 112.408 114.444 111.616H114.418V111.59Z"
                    fill="black"
                />
                <path
                    d="M100.383 122.583C100.358 122.046 100.383 121.535 100.485 121.049L71.0604 102.515C70.3957 102.08 70.1912 101.211 70.6002 100.546C70.8558 100.111 71.3416 99.8559 71.8273 99.8559C72.083 99.8559 72.313 99.9325 72.5687 100.06L102.147 117.905C102.684 117.368 103.323 116.933 104.039 116.601L73.6168 98.2453C71.9807 97.2482 69.8333 97.7851 68.8107 99.4212C67.7881 101.083 68.3249 103.23 69.9355 104.279L100.537 123.554C100.46 123.222 100.409 122.89 100.383 122.557V122.583Z"
                    fill="black"
                />
                <path
                    d="M166.059 91.7516C165.292 90.32 163.477 89.7575 162.045 90.5245L112.092 117.24C112.629 117.7 113.14 118.262 113.549 118.85L163.017 92.3907C163.451 92.1606 163.988 92.314 164.218 92.7742C164.448 93.2088 164.295 93.7456 163.86 93.9757L114.699 121.535C114.776 122.046 114.802 122.557 114.751 123.068C114.699 123.401 114.648 123.682 114.572 124.014L164.909 95.8164C166.34 95.0239 166.877 93.2088 166.085 91.7772L166.059 91.7516Z"
                    fill="black"
                />
              </g>
              <path
                  d="M46.3137 62.6594C46.3137 62.6594 46.2114 62.7616 46.1858 62.8128C46.1603 62.8383 46.1347 62.8895 46.1091 62.915C46.0325 63.0173 45.9302 63.094 45.8535 63.1707L45.3422 63.682C45.1633 63.8609 44.9843 64.0399 44.8309 64.2188C44.7542 64.2955 44.6775 64.3722 44.5753 64.4233C44.5241 64.4745 44.473 64.5512 44.3963 64.6279C44.1407 64.9091 43.885 65.1391 43.476 65.1391C42.888 65.1391 42.3767 64.6279 42.3767 64.0143C42.3767 63.7331 42.5045 63.4519 42.709 63.2474C42.709 63.2474 42.709 63.2474 42.7346 63.1962L42.7857 63.1195C42.7857 63.1195 42.8624 62.9917 42.9136 62.9661C42.9391 62.9406 42.9647 62.915 42.9903 62.8895C43.0158 62.8895 43.0158 62.8639 43.0414 62.8383C43.0414 62.8383 43.0669 62.8383 43.0669 62.8128C43.0925 62.7616 43.1181 62.7361 43.1692 62.7105L43.2715 62.6082C43.2715 62.6082 43.297 62.6082 43.3226 62.5827C43.476 62.4037 43.6549 62.2248 43.8083 62.0458C43.9873 61.8413 44.1662 61.6112 44.3452 61.3811C44.4474 61.2533 44.5753 61.1255 44.7287 60.9977C44.3452 60.8443 43.9361 60.7676 43.5271 60.7676C41.7887 60.7676 40.3571 62.1992 40.3571 63.9632C40.3571 65.7271 41.7887 67.1588 43.5271 67.1588C45.2655 67.1588 46.6971 65.7271 46.6971 63.9632C46.6971 63.4774 46.5693 62.9917 46.3648 62.5827L46.3137 62.6594Z"
                  fill="black"
              />
              <path
                  d="M22.7174 99.4981C22.7174 99.4981 22.7686 99.3702 22.7941 99.2936C22.7941 99.2424 22.7941 99.2169 22.8197 99.1657C22.8453 99.0379 22.8964 98.9356 22.9219 98.8078C22.9986 98.5777 23.0753 98.3477 23.152 98.092C23.2287 97.8619 23.3054 97.6063 23.3565 97.3762C23.4077 97.2484 23.4332 97.1717 23.4588 97.0694C23.4844 96.9927 23.5099 96.916 23.5355 96.8138C23.6378 96.4303 23.7656 96.1235 24.1235 95.9446C24.6603 95.6634 25.325 95.8679 25.6062 96.4303C25.7596 96.686 25.7852 96.9672 25.7085 97.2484C25.7085 97.2484 25.7085 97.2739 25.7085 97.2995C25.7085 97.3762 25.6829 97.3762 25.6829 97.4018C25.6829 97.4785 25.6574 97.5296 25.6318 97.6063V97.683C25.6318 97.683 25.6318 97.7341 25.6062 97.7597V97.8108C25.6062 97.8108 25.5807 97.9131 25.5551 97.9386C25.5295 97.9898 25.504 98.0153 25.504 98.0664C25.504 98.0664 25.4784 98.092 25.4784 98.1176C25.4273 98.3477 25.3506 98.5777 25.2995 98.8078C25.2228 99.0635 25.1716 99.3702 25.1205 99.6259C25.0694 99.8049 25.0183 99.9582 24.9416 100.137C25.3506 100.112 25.7341 99.9838 26.1175 99.8048C27.677 98.9868 28.2905 97.0694 27.498 95.4844C26.7055 93.925 24.7882 93.3114 23.2032 94.1039C21.6437 94.8964 21.0302 96.8393 21.8227 98.4244C22.0527 98.859 22.3595 99.2169 22.743 99.4981H22.7174Z"
                  fill="black"
              />
              <mask
                  id="mask1_1131_15121"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="53"
                  y="0"
                  width="22"
                  height="51"
              >
                <path d="M74.3327 0H53.8809V50.3625H74.3327V0Z" fill="white"/>
              </mask>
              <g mask="url(#mask1_1131_15121)">
                <path
                    d="M57.6903 49.4933H55.594V22.1391C55.594 20.0428 57.2813 18.381 59.3264 18.381H64.6183V0H71.1629V41.9773H69.0666V2.09631H66.6891V20.4774H59.3009C58.4061 20.4774 57.6392 21.2187 57.6392 22.1391V49.4933H57.6903Z"
                    fill="black"
                />
              </g>
              <path
                  d="M61.6781 46.8348H59.5818V21.9092H66.7144V43.8437H64.6181V24.0055H61.6526V46.8348H61.6781Z"
                  fill="black"
              />
              <mask
                  id="mask2_1131_15121"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="142"
                  y="0"
                  width="21"
                  height="51"
              >
                <path d="M162.965 0H142.514V50.3625H162.965V0Z" fill="white"/>
              </mask>
              <g mask="url(#mask2_1131_15121)">
                <path
                    d="M159.923 49.4933H157.827V22.1391C157.827 21.2187 157.086 20.4774 156.165 20.4774H148.777V2.09631H146.4V41.9517H144.303V0H150.848V18.381H156.14C158.211 18.381 159.872 20.0683 159.872 22.1391V49.4933H159.923Z"
                    fill="black"
                />
              </g>
              <path
                  d="M155.935 46.8348H153.839V24.0055H150.874V43.8437H148.803V21.9092H155.935V46.8604V46.8348Z"
                  fill="black"
              />
              <mask
                  id="mask3_1131_15121"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="53"
                  y="193"
                  width="22"
                  height="39"
              >
                <path
                    d="M74.3327 193.525H53.8809V232H74.3327V193.525Z"
                    fill="white"
                />
              </mask>
              <g mask="url(#mask3_1131_15121)">
                <path
                    d="M71.2396 231.975H64.6439V227.425H59.352C57.2813 227.425 55.6196 225.738 55.6196 223.667V196.312H57.7159V223.667C57.7159 224.562 58.4573 225.328 59.3776 225.328H66.7658V229.879H69.1433V203.829H71.2396L71.2908 231.95L71.2396 231.975Z"
                    fill="black"
                />
              </g>
              <path
                  d="M66.7144 223.922H59.5818V198.971H61.6781V221.826H64.6436V201.962H66.7399V223.922H66.7144Z"
                  fill="black"
              />
              <mask
                  id="mask4_1131_15121"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="142"
                  y="193"
                  width="21"
                  height="39"
              >
                <path
                    d="M162.965 193.525H142.514V232H162.965V193.525Z"
                    fill="white"
                />
              </mask>
              <g mask="url(#mask4_1131_15121)">
                <path
                    d="M150.874 232.001H144.252L144.329 203.854H146.425V229.904H148.803V225.354H156.191C157.086 225.354 157.853 224.612 157.853 223.692V196.338H159.949V223.692C159.949 225.763 158.262 227.45 156.217 227.45H150.925V232.001H150.874Z"
                    fill="black"
                />
              </g>
              <path
                  d="M155.935 223.922H148.803V201.962H150.874V221.826H153.839V198.971H155.935V223.922Z"
                  fill="black"
              />
              <mask
                  id="mask5_1131_15121"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="193"
                  y="108"
                  width="18"
                  height="31"
              >
                <path
                    d="M210.388 108.317H193.643V138.995H210.388V108.317Z"
                    fill="white"
                />
              </mask>
              <g mask="url(#mask5_1131_15121)">
                <path
                    d="M195.97 136.234L195.739 134.138L206.144 132.885C207.295 132.757 208.164 131.76 208.164 130.584V115.322C208.164 114.146 207.295 113.149 206.144 113.021L195.739 111.743L195.995 109.646L206.4 110.925C208.599 111.18 210.26 113.072 210.26 115.322V130.584C210.26 132.808 208.599 134.7 206.4 134.981L195.995 136.234H195.97Z"
                    fill="black"
                />
              </g>
              <path
                  d="M196.558 131.352L196.225 129.255L201.261 128.488C202.361 128.309 203.153 127.364 203.153 126.264V119.643C203.153 118.518 202.335 117.572 201.261 117.419L196.2 116.626L196.506 114.556L201.568 115.323C203.69 115.655 205.25 117.47 205.25 119.643V126.264C205.25 128.412 203.716 130.252 201.568 130.559L196.532 131.352H196.558Z"
                  fill="black"
              />
              <path d="M148.496 0H65.5127V2.04518H148.496V0Z" fill="black"/>
              <path
                  d="M148.445 229.929H70.3445V231.974H148.445V229.929Z"
                  fill="black"
              />
              <path
                  d="M31.1028 82.4715C32.9946 78.8925 35.142 75.4412 37.5196 72.1689C38.0053 72.5269 38.5166 72.8848 39.0023 73.1915C39.0534 73.2427 39.1301 73.2682 39.1813 73.3194C36.8549 76.4894 34.7586 79.8639 32.9179 83.3663C32.3043 83.034 31.7164 82.7272 31.1028 82.446V82.4715Z"
                  fill="black"
              />
              <path
                  d="M28.035 82.5485C27.9071 82.2928 27.9583 82.4462 28.035 82.5485C28.0094 82.4973 27.9583 82.4462 27.9327 82.3951C27.856 82.2928 27.7793 82.165 27.7026 82.0627C27.5492 81.8582 27.4214 81.6281 27.3191 81.3981C27.2936 81.3469 27.268 81.2958 27.2425 81.2447C27.4214 81.5003 27.3191 81.3981 27.2425 81.2958C27.2425 81.2447 27.2169 81.1935 27.1913 81.1424C28.5974 78.4581 30.0801 75.8249 31.7163 73.3196C32.5088 73.294 33.3013 73.3196 34.0682 73.3707C34.0682 73.3707 34.0682 73.3707 34.0938 73.3707C31.9975 76.4641 30.1057 79.7108 28.4184 83.0853C28.265 82.9064 28.1372 82.753 27.9838 82.574L28.035 82.5485Z"
                  fill="black"
              />
              <path
                  d="M29.0319 86.817C29.3387 86.1268 29.6454 85.411 29.9778 84.7207C29.9778 84.7207 29.9778 84.7207 30.0033 84.7207C30.0033 84.7207 30.0033 84.7207 30.08 84.7463C30.2079 84.823 30.3613 84.8741 30.4891 84.9508C30.9237 85.1809 31.3327 85.411 31.7673 85.641C31.4605 86.2802 31.1793 86.9193 30.8981 87.5584L29.0063 86.7915L29.0319 86.817Z"
                  fill="black"
              />
            </svg>
          </div>
        </div>
        <div class="product__properties-text descriptions">
          <p>
            {{ product.description }}
          </p>
        </div>

        <div class="product__properties-text delivery">
          <p>
            <span class="font-bold">Бесплатная доставка наручных часов по всей России. </span><br>
            Осуществляется с помощью ТК СДЕК (до пункта выдачи) или Почтой России. (занимает от 2 до 10 рабочих
            дней)<br>

            При получении в отделениях СДЕК возможна примерка и осмотр товара перед оплатой.<br>

            Доставку по Севастополю и ближайшим районам мы осуществляем сами, что дает дополнительные возможности и
            преимущества, такие как:<br>

            - Выбор на дому из нескольких моделей (Если у Вас нет возможности подъехать в наш салон, то мы привезем до 5
            моделей на выбор.
            Их можно осмотреть, примерять и определиться прямо у Вас дома)<br>

            <span class="font-bold green-text">**</span> При покупке часов доставка бесплатная. Если Вам ничего не
            подошло, стоимость доставки 250 руб.<br>

            - Сразу при покупке мы бесплатно укоротим браслет или сделаем замену ремешка.<br>

            - Расскажем тонкости настройки механизма и особенности функций.<br>

            Стоимость доставки аксессуаров, ремней и настенных часов уточняется у менеджера.
          </p>
        </div>
        <div class="product__properties-text payment">
          <p>
            Lorem Ipsum- это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. <br/><br/>
            Lorem Ipsum- это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн.
          </p>
        </div>
        <div class="product__properties-text guarantee">
          <p>
            Lorem Ipsum- это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн. <br/><br/>
            Lorem Ipsum- это текст-"рыба", часто используемый в печати и
            вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на
            латинице с начала XVI века. В то время некий безымянный печатник
            создал большую коллекцию размеров и форм шрифтов, используя Lorem
            Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил
            без заметных изменений пять веков, но и перешагнул в электронный
            дизайн.
          </p>
        </div>
      </div>
      <div class="viewProduct">
        <div class="viewProduct__head">
          <h2 class="sub-head-h2 black">
            Недавно просмотренные ({{ quantityViewProducts }})
          </h2>
        </div>
        <Swiper
            class="viewProduct__list"
            :slides-per-view="5"
            :breakpoints="{
            320: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
            },
          }"
        >
          <SwiperSlide
              class="viewProduct__item"
              v-for="product in viewProducts"
              :key="product.id"
          >
            <mini-product :product="product"></mini-product>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
  <PopupOrderVue :msg="'fastOrder'"
                 v-if="showModal"
                 v-on:closePopup="showModal = false"
                 v-on:update:msg="updateMsg"
                 :product_id="product.id"
                 :product_name="product.name"
  ></PopupOrderVue>
  <PopupOrderVue :msg="'login'"
                 v-if="showModalLogin"
                 v-on:closePopup="showModalLogin = false"
                 v-on:update:msg="updateMsg"
  ></PopupOrderVue>

  <FooterApp></FooterApp>
</template>

<style scoped>
.viewProduct {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.product__properties {
  padding-bottom: 100px;
}

.product__plash {
  position: absolute;
  top: 143px;
  right: 0;
}

.head__properties {
  color: #b5b5b5;
  font-family: "PT Sans Narrow";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 20px;
  cursor: pointer;
  border-bottom: transparent 1px solid;
}

.product__properties-text {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: none;
  margin-top: 35px;
}

.product__properties-text.active {
  display: block;
}

.product__properties-text.characteristics.active {
  display: flex;
  justify-content: space-between;
}

.characteristic__item {
  display: flex;
  justify-content: space-between;
  gap: 45px;
  align-items: center;
  padding: 34px 35px 34px 27px;
  background: rgba(91, 164, 83, 0.04);
  max-width: 50%;
}

.attributes {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 616px;
}

.head__properties:hover {
  color: #1f1f1f;
  border-bottom: #181818 1px solid;
  padding-bottom: 20px;
}

.head__properties:has(a.active) {
  color: #1f1f1f;
  border-bottom: #181818 1px solid;
  padding-bottom: 20px;
}

.nav__properties {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #b5b5b5;
}

.attributes {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 616px;
}

.attributes__item {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.attributes__item p {
  white-space: nowrap;
}

.attributes__item span.line-item {
  width: 100%;
  height: 1px;
  background-color: #b5b5b5;
  align-self: flex-end;
  margin-bottom: 7px;
}

.product__wishlist div svg {
  cursor: pointer;
}

.product__wishlist div svg:hover path {
  stroke: #397e33;
}

.product__wishlist {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: auto;
}

.product__wishlist div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.product_info-text {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.product_info-text span {
  color: #397e33;
}

.green-text {
  color: #397e33;
}

.product__special {
  display: flex;
  align-items: center;
  gap: 15px;
}

.product_cart {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.next-product {
  position: relative;
}

.next-product:after {
  content: "Следующий товар";
  position: absolute;
  bottom: -15px;
  right: 0;
  color: #d9d9d9;
  font-family: "PT Sans Narrow";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.next-product:hover svg path {
  fill: #397e33;
}

.next-product:hover::after {
  color: #397e33;
}

.info-prod {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 56px;
}

.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.btns__addToCart {
  display: flex;
  align-items: center;
  gap: 15px;
}

.btn__addToCart {
  position: static;
  transform: none;
  opacity: 1;
  visibility: visible;
}

.btn {
  background: #397e33;
  color: #fff;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px;
  border: 1px solid #397e33;
  width: 248px;
  cursor: pointer;
}

.btn__buy {
  background: transparent;
  color: #1f1f1f;
  border: 1px solid #1f1f1f;
}

.price__old {
  color: #a5a5a5;
}

.action {
  color: #a5a5a5;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}

.price {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 50.4px */
  display: flex;
  gap: 13px;
}

.product__content {
  display: flex;
  gap: 81px;
}

.product__head {
  display: flex;
  flex-direction: column;
  gap: 9px;
  position: relative;
}

.product__attribute {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product__title {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.product__attribute-item {
  display: flex;
  gap: 5px;
  color: #a5a5a5;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.manufacturer {
  color: #181818;
}

.go-to-scroll {
  transition: transform 0.3s, color 0.3s;
}

.go-to-scroll.hovered svg path {
  animation: colorChange 1s infinite alternate;
}

.go-to-scroll.hovered svg {
  transition: all 0.3s;
}

.go-to-scroll.hovered svg {
  animation: upAnim 1s infinite linear;
}

.hoverOne:hover .st0 path {
  fill: #397e33;
  transition: all 0.3s;
}

.hoverTwo:hover .st3 path {
  fill: #397e33;
  transition: all 0.3s;
}

.font-bold {
  font-weight: bold;
}

@keyframes upAnim {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes colorChange {
  0% {
    fill: #181818;
  }
  100% {
    fill: green;
  }
}

.swiper-slide {
  height: 100%;
}

.product__img {
  height: 100%;
}

.swiper {
  width: 100%;
  max-width: 649px;
  padding: 41px;
  margin: 0;
  border: 1px solid #d9d9d9;
}

span.line {
  width: 1px;
  background-color: #d9d9d9;
}

span.line:nth-of-type(1),
span.line:nth-of-type(6) {
  height: 5%;
}

span.line:nth-of-type(2),
span.line:nth-of-type(5) {
  height: 15%;
}

span.line:nth-of-type(3),
span.line:nth-of-type(4) {
  height: 28%;
}

.go-to-scroll {
  position: relative;
  width: 2px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.gallery {
  display: flex;
  gap: 24px;
  max-height: 649px;
}

.nav__miniPhoto {
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: scroll;
  flex-shrink: 0;
}

.nav__miniPhoto::-webkit-scrollbar {
  display: none;
}

.miniPhoto {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 14px;
  width: 157px;
  height: 157px;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.miniPhoto img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.product__content {
  margin-bottom: 130px;
}

.product-head__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-head {
  position: relative;
}

.socials {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 89px;
  margin-bottom: 35px;
}

.links {
  display: flex;
  align-items: center;
  gap: 8px;
}

.viewProduct__list.swiper {
  max-width: none;
  padding: 0;
  border: none;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.arrowsShop {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: 3;
}

.swiper-button-lock {
  display: block;
}

@media screen and (max-width: 1440px) {
  .swiper {
    max-width: 524px;
  }

  .miniPhoto {
    width: 126px;
    height: 126px;
  }

  .gallery {
    max-height: 524px;
  }

  .info-prod {
    gap: 30px;
  }

  .characteristic__item {
    padding: 27px 25px 22px 12px;
    gap: 9px;
  }

  .product__content {
    gap: 30px;
  }

  .btns__addToCart {
    flex-wrap: wrap;
  }

  .attributes__item.complekt p {
    white-space: inherit;
    align-self: self-end;
    text-align: right;
  }
}

@media screen and (max-width: 1024px) {
  .product__content {
    flex-direction: column;
  }

  .product__plash {
    display: none;
  }

  .next-product {
    display: none;
  }

  .go-to-scroll {
    display: none;
  }

  .nav__properties {
    gap: 200px;
    overflow-x: scroll;
  }

  .nav__properties::-webkit-scrollbar {
    display: none;
  }

  .product__properties-text.characteristics.active {
    flex-direction: column;
  }

  .product__properties-text {
    font-size: 16px;
  }

  .attributes {
    max-width: 100%;
  }

  .product__title {
    font-size: 42px;
  }

  .characteristic__item {
    max-width: none;
  }
}

@media screen and (max-width: 660px) {
  .characteristic__item {
    flex-direction: column;
    max-width: none;
  }

  .attributes__item.complekt p {
    white-space: inherit;
    align-self: self-end;
    text-align: right;
  }

  .product-head__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .socials {
    margin: 0 0 25px 0;
  }

  .product__properties-text {
    font-size: 16px;
  }

  .nav__properties {
    gap: 25px;
  }

  .product__title {
    font-size: 30px;
  }

  .gallery {
    flex-direction: column-reverse;
    gap: 9px;
  }

  .nav__miniPhoto {
    flex-direction: row;
  }

  .miniPhoto {
    width: 86px;
    height: 86px;
  }

  .swiper {
    padding: 23px;
  }

  .price {
    font-size: 26px;
  }

  .btn {
    width: 100%;
  }

  .btns__addToCart {
    flex-direction: column;
    width: 100%;
  }

  .product__content {
    margin-bottom: 72px;
  }

  .head__properties {
    font-size: 22px;
  }
}
</style>