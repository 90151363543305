<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import MainBtn from "../ui/main-btn.vue";
export default {
  name: "HomeAboutBlock",
  props: {
    page: {
      type: String,
      required: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    MainBtn,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper3 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper3,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  methods: {},
};
</script>

<template>
  <div class="home-about-block">
    <div class="container-fluid">
      <div class="home-about__content">
        <div class="slider">
          <div class="arrowsAbout">
            <a class="prev-3" :class="{ 'grey-icon': isFirstSlide }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M11.8749 18.9999C11.8748 19.1559 11.9054 19.3103 11.9651 19.4544C12.0248 19.5985 12.1124 19.7294 12.2229 19.8395L24.0979 31.7145C24.5619 32.1785 25.3133 32.1785 25.777 31.7145C26.2407 31.2505 26.241 30.4991 25.777 30.0354L14.7416 18.9999L25.777 7.96449C26.241 7.50047 26.241 6.74908 25.777 6.28536C25.313 5.82164 24.5616 5.82134 24.0979 6.28536L12.2229 18.1604C12.1124 18.2705 12.0248 18.4014 11.9651 18.5454C11.9054 18.6895 11.8748 18.844 11.8749 18.9999Z"
                  fill="#D9D9D9"
                />
              </svg>
            </a>
            <a class="next-3" :class="{ 'grey-icon': isLastSlide }">
              <!-- Добавлено применение класса grey-icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
              >
                <path
                  d="M26.125 18.9999C26.1251 19.1559 26.0945 19.3103 26.0347 19.4544C25.975 19.5985 25.8874 19.7294 25.777 19.8395L13.902 31.7145C13.438 32.1785 12.6866 32.1785 12.2229 31.7145C11.7592 31.2505 11.7589 30.4991 12.2229 30.0354L23.2583 18.9999L12.2229 7.96449C11.7589 7.50047 11.7589 6.74908 12.2229 6.28536C12.6869 5.82164 13.4383 5.82134 13.902 6.28536L25.777 18.1604C25.8874 18.2705 25.975 18.4014 26.0347 18.5454C26.0945 18.6895 26.1251 18.844 26.125 18.9999Z"
                  fill="#D9D9D9"
                />
              </svg>
            </a>
          </div>
          <Swiper
            :slides-per-view="'auto'"
            :space-between="24"
            :modules="modules"
            :reverseDirection="true"
            :navigation="{ enabled: true, prevEl: '.prev-3', nextEl: '.next-3' }"
            :breakpoints="{
              0: {
                slidesPerView: 1,
              },
              550: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 'auto',
              },
            }"
            @swiper="onSwiper3"
            class="mySwiper"
          >
            <SwiperSlide>
              <img
                src="../../assets/img/about1.jpg"
                alt=""
                class="home-about__img"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="../../assets/img/about2.jpg"
                alt=""
                class="home-about__img"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="../../assets/img/about3.jpg"
                alt=""
                class="home-about__img"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="home-about__head">
          <h2 v-if="page === 'home'" class="head-h2">О компании</h2>
          <div class="home-about__text">
            <p>Мы, Евгений и Анастасия - владельцы салонов Библиотека Часов.</p>
            <p>
              Сегодня часы сопровождают нас во всех жизненных ситуациях.
              Помогают считать шаги, засекать время, погружаются с нами под
              воду, восходят на вершины гор, показывают направления,
              температуру, изменения погодных условий и фаз Луны.
            </p>
            <p>
              Но самое главное предназначение часов - это дополнять Ваш образ.
              Мы очень рады способствовать Вам в этом. За шесть лет работы у нас
              более 10 тысяч покупателей и это только те люди, которые оформили
              Карту лояльности магазина.
            </p>
            <p>
              Мы начинали с совсем маленького отдела и нескольких марок часов,
              мы росли вместе с вами, развивались для вас, делали ребрендинг и
              добавляли марки по вашим пожеланиям. сейчас у нас два салона , 26
              брендов в наличии и широкие возможности работы под заказ.
            </p>
            <p>
              Так же мы являемся официальным сервисным центром для 27 брендов и
              выполняем не гарантийный ремонт.
            </p>
            <p>
              Оформив Карту привилегий от сети БЧ Вы навсегда станете частью
              компании. Для владельцев карты мы предусмотрели скидки, бонусы и
              дополнительные условия сервисного обслуживания. Бесплатно оформить
              такую Карту мы предложим вам при первой покупке.
            </p>
            <p>Мы стараемся иметь и уметь делать все что часы окружает.</p>
            <p>
              Мы регулируем Браслеты по руке бесплатно при покупке, меняем
              Батарейки и ремешки, полируем корпуса, меняем Стекла. Имеем всегда
              в наличии ремни и браслеты, Шкатулки для Хранения часов и
              разбираемся в настройке любых механизмов.
            </p>
            <p>
              Мы благодарны за время, которое вы нам уделили и будем рады быть
              вашим проводником в мир часов.
            </p>
          </div>
          <MainBtn
            class="home-about__btn"
            :className="'black'"
            :link="'/about'"
            :msg="'Подробнее'"
          ></MainBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.home-about-block {
  padding-bottom: 168px;
}
.mySwiper {
  direction: rtl;
}
.container-fluid {
  width: 100%;
  padding: 0 35px 0 0;
}
.mySwiper .swiper-wrapper {
  direction: ltr;
}
.mySwiper {
  height: 100%;
  flex-shrink: 0;
}
.swiper-slide {
  width: 536px;
  height: 769px;
  max-width: 100%;
}
.swiper-slide img {
  height: 100%;

  object-fit: cover;
  object-position: center;
}
.home-about__content {
  display: flex;
  gap: 29px;
}
.slider {
  position: relative;
  width: 50%;
}
.home-about__head {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-shrink: 0;
  width: calc(50% - 25px);
  justify-content: space-between;
}
.home-about__btn {
  margin-top: auto;
}
.home-about__text {
  color: #1f1f1f;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 906px;
}
.arrowsAbout {
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 3;
  justify-content: space-between;
}
@media screen and (max-width: 1440px) {
  .swiper-slide {
    width: 410px;
    height: 759px;
    max-width: 100%;
  }
  .home-about__text {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-slide {
    width: 364px;
    height: 517px;
    max-width: 100%;
  }
  .arrowsAbout {
    display: flex;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 3;
    justify-content: space-between;
  }
  .home-about__text {
    font-size: 16px;
  }
  .home-about__content {
    flex-direction: column-reverse;
  }
  .mySwiper {
    width: 100%;
  }
  .container-fluid {
    padding: 0 12px;
  }
  .home-about__head {
    width: 100%;
  }
  .mySwiper {
    direction: ltr;
  }
  .home-about-block {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 550px) {
  .home-about__btn {
    width: 100%;
  }
  .swiper-slide{
    height: 100vh;
  }
}
</style>