<script>
import mainBtnVue from "../ui/main-btn.vue";
export default {
  name: "HelpChoice",
  components: {
    mainBtnVue,
  },
  data(){
    return{
      userPhone: '',
      userName: ''
    }
  }
};
</script>

<template>
  <div class="popup" >
    <div key="login" class="popup__content">
      <div class="popup__head">
        <div class="sub-head-h2 black">Заявка на подбор часов</div>
        <div class="fs-12 black">Наш менеджер подберет модель по нужным характеристикам и пришлет фото и видео обзор</div>
        <a class="close-popup" @click="$emit('closePopup')">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
          >
            <path
                d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
                fill="#181818"
            />
          </svg>
        </a>
      </div>
      <div class="popup__form">
        <div class="popup__form-input">
          Имя
          <input
            @input="userName"
            class="input"
            type="text"
            placeholder="Имя"
          />
        </div>
        <div class="popup__form-input">
          Номер телефона
          <input
            @input="userPhone"
            class="input"
            type="text"
            placeholder="Номер телефона"
          />
        </div>
      </div>
      <main-btn-vue :className="'green'"
                    :msg="'Отправить'"></main-btn-vue>
    </div>
  </div>
</template>

<style scoped>
.popup {
  background: rgba(31, 31, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popup__content {
  background: #fff;
  padding: 54px 72px 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  width: 854px;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
}
.popup__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.popup__sub-title {
  color: #181818;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.popup__sub-title a {
  color: #397e33;
  text-decoration: underline;
}
.popup__form-input {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.popup__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.popup__form-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.popup__form-checkbox a {
  color: #397e33;
  text-decoration: underline;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.fs-12{
  font-size: 12px;
}
</style>