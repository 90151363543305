<script>
import Cookies from "js-cookie";

export default {
  name: 'App',
  components: {

  },
  mounted() {
    let username = Cookies.get("username");
    let authKey = Cookies.get("auth_key");
    const role = Cookies.get("role");



    if (username && authKey) {

      let user = {
        user_id: parseInt(Cookies.get("user_id")),
        username: Cookies.get("username"),
        email: Cookies.get('email'),
        auth_key: authKey,
        role: {
          item_name: role,
        },
        isLogged: Cookies.get("isLogged"),
        profile: Cookies.get("profile") ? JSON.parse(Cookies.get("profile")) : [],
        wishlist: Cookies.get("wishlist") ?  Cookies.get("wishlist") : [],
        compare: Cookies.get("compare") ? Cookies.get("compare") :[],
        cart: Cookies.get("cart") ?  Cookies.get("cart") : []
      }

      this.$store.commit("setUserData", user);
      this.$store.commit("setLogged", user.isLogged);
      this.$store.commit("setWishlistProductIds", user.wishlist);
      this.$store.commit("setCompareProductIds", user.compare);
      this.$store.commit("setCart", JSON.parse(user.cart));
    }
  },
  data(){

  }
}
</script>

<template>
  <router-view></router-view> 
</template>

<style>
.cursor{
  cursor: pointer;
}
</style>
