<script>
import MainBtn from "../ui/main-btn.vue";
import axios from "axios";
export default {
  name: "HomeMainBlock",
  components: {
    MainBtn
  },
  data(){
    return{
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      banner: ''
    }
  },
  methods:{
    getBanner(){
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
          .get(`${this.apiUrl}api/banner/get-banner-home${params}`)
          .then((response) => {

            this.banner = this.apiDomain + "/web/uploads" + response.data[0].pic
            console.log('banner', this.banner)
          })
          .catch((error) => {
            console.log('error', error)
          })
    }
  },
  mounted() {
    this.getBanner()
  }
};
</script>

<template>  
    <div class="home-main-block" :style="{ 'backgroundImage': `url(${banner})` }">
        <div class="container h100">
            <div class="home-main__content">
                <div class="home-main__text">
                    стоит посетить <br>
                    стоит примерить <br>
                    стоит купить

                    <main-btn style="margin-top: 30px" href="/shop" productId="0" :msg="'Купить'"></main-btn>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.home-main-block{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    height: calc(100vh - 89px);
}
.home-main__content{
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 156px;
}
.home-main__text{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 58.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
}
@media screen and (max-width: 1028px) {
  .home-main__content {
    padding-left: 41px;
  }
  .home-main__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 48.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .home-main-block {
    background-position: center;
  }
}
@media screen and (max-width: 550px) {
  .home-main__content {
    padding-left: 0px;
  }
  .home-main__text {
    color: #fff;
    font-family: "PT Sans Narrow";
    font-size: 32.77px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
  }
  .home-main-block {
    background-position: center;
  }
}
</style>