<script>
import mainBtnVue from "../ui/main-btn.vue";
import axios from "axios";
import Cookies from "js-cookie";
import {mapState} from "vuex";
import { MaskInput } from 'vue-3-mask';

export default {
  name: "PopupOrder",
  components: {
    mainBtnVue,
    MaskInput
  },
  props: {
    msg: String,
    link: String,
    product_id: String,
    product_name: String,
  },
  computed: mapState({
    userData: (state) => state.user
  }),
  methods: {
    switchTo(mess) {
      this.message = mess;
      console.log("switchToRegistration", this.msg);
    },
    userRegister(){

      let phone = this.phoneRegister.replace(/[\D]/g, () => '');
      console.log('phone', phone)
      let params = {
        username: phone,
        password: this.passwordRegister,
        i: this.nameRegister
      }
      console.log('params', params)
      axios
          .post(this.apiUrl + "api/user/register", params,{headers:{
            "Content-Type": "multipart/form-data"
            }})
          .then((response) => {
            console.log(response);

            if(response.data.status){
              let user = response.data.user
              console.log('user', user)
              user.isLogged = true;
              this.error = ''
              this.$emit('closePopup')
              Cookies.set("user_id", user.id, { expires: 7 });
              Cookies.set("username", user.username, { expires: 7 });
              Cookies.set("auth_key", user.auth_key, { expires: 7 });
              Cookies.set("role", user.role.item_name, { expires: 7 });
              Cookies.set("profile", user.profile.i, { expires: 7 });
              this.$store.commit('setUserData', user)
              this.$store.commit('setLogged', user.isLogged)
            }else{
              this.error = response.data.error
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    userLogin(){
      let phone = this.phoneLogin.replace(/[\D]/g, () => '');
      let params = {
        username: phone,
        password: this.passwordLogin,
      }
      console.log('params', params)
      axios
          .post(this.apiUrl + "api/user/login", params,{headers:{
              "Content-Type": "application/x-www-form-urlencoded"
            }})
          .then((response) => {
            console.log(response);

            if(response.data.status){
              let user = response.data.user
              user.isLogged = true;
              this.error = ''
              this.$emit('closePopup')

              let cart = {
                  id: response.data.cartId,
                  productIds:response.data.productCart
              }
              console.log('response.data', response.data)
              Cookies.set("user_id", parseInt(user.id), { expires: 7 });
              Cookies.set("username", user.username, { expires: 7 });
              Cookies.set("email", user.email, { expires: 7 });
              Cookies.set("auth_key", user.auth_key, { expires: 7 });
              Cookies.set("role", user.role.item_name, { expires: 7 });
              Cookies.set("isLogged", user.isLogged, { expires: 7 });
              Cookies.set("profile", JSON.stringify(user.profile), { expires: 7 });
              Cookies.set("cart", JSON.stringify(cart), { expires: 7 });
              Cookies.set("wishlist", response.data.productsLike, { expires: 7 });
              Cookies.set("compare", response.data.productsCompare, { expires: 7 });
              user.user_id = user.id
              this.$store.commit('setUserData', user)
              this.$store.commit('setUserData', JSON.parse(user.profile))
              this.$store.commit('setLogged', user.isLogged)
              this.$store.commit('setCart', cart)
              this.$store.commit('setWishlistProductIds', response.data.productsLike)
              this.$store.commit('setCompareProductIds', response.data.productsCompare)
              window.location.reload()
            }else{
              this.error = response.data.error
            }

          })
          .catch((error) => {
            console.log(JSON.stringify(error));
          });
    },

  },
  data() {
    return {
      message: "Hello!",
      apiUrl: this.$store.getters.getApiUrl,
      login: "",
      pass: "",
      nameRegister: '',
      phoneRegister: '',
      passwordRegister: '',
      phoneLogin: '',
      passwordLogin: '',
      error: '',
    };
  },
  mounted() {
    this.message = this.msg;
  },
};
</script>

<template>
  <div class="popup">
    <div v-if="message == 'login'" key="login" class="popup__content">
      <div class="popup__head">
        <div class="sub-head-h2 black">Авторизация</div>
        <div class="popup__sub-title">
          Еще нет аккаунта?
          <a @click.prevent="switchTo('registration')"> Зарегистрироваться</a>
        </div>
      </div>
      <div class="popup__form">
        <div class="popup__form-input">
          Номер телефона
          <MaskInput v-model="phoneLogin" class="input inputLogin" mask="+7(###) ###-##-##" type="text" placeholder="+7(999) 999-99-99"></MaskInput>
        </div>
        <div class="popup__form-input">
          Пароль *
          <input
            @input="PassRequest"
            class="input inputPass"
            type="password"
            v-model="passwordLogin"
            placeholder="Пароль"
          />
        </div>
        <p class="invalid" style="border: none">{{this.error}}</p>
        <div class="popup__form-checkbox">
          <div class="checkbox">
            <input class="input" type="checkbox" id="checkbox" />
            <label for="checkbox">Запомнить меня</label>
          </div>
          <a @click.prevent="switchTo('recovery')">Забыли пароль?</a>
        </div>
      </div>
      <div class="popup__footer">
        <mainBtnVue
          @click="userLogin"
          :className="'green'"
          :msg="'Войти'"
        ></mainBtnVue>
      </div>
      <a class="close-popup" @click="$emit('closePopup')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
            fill="#181818"
          />
        </svg>
      </a>
    </div>
    <div
      v-else-if="message == 'registration'"
      key="registration"
      class="popup__content"
    >
      <div class="popup__head">
        <div class="sub-head-h2 black">Регистрация</div>
        <div class="popup__sub-title">
          Уже есть аккаунт?
          <a @click.prevent="switchTo('login')">Авторизоваться</a>
        </div>
      </div>
      <div class="popup__form">
        <div class="popup__form-input">
          Имя *
          <input class="input" type="text" placeholder="Введите имя" v-model="nameRegister" />
        </div>
        <div class="popup__form-input">
          Номер телефона
          <MaskInput v-model="phoneRegister" class="input inputLogin" mask="+7(###) ###-##-##" type="text" placeholder="+7(999) 999-99-99"></MaskInput>
        </div>
        <div class="popup__form-input">
          Пароль *
          <input class="input" type="password" placeholder="Пароль"  v-model="passwordRegister" />
        </div>
      </div>
      <p class="invalid" style="border: none">{{this.error}}</p>
      <div class="popup__footer">
        <mainBtnVue
          :className="'green'"
          :msg="'Зарегистрироваться'"
          @click="userRegister()"
        ></mainBtnVue>
      </div>
      <a class="close-popup" @click="$emit('closePopup')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
            fill="#181818"
          />
        </svg>
      </a>
    </div>
    <div
      v-else-if="message == 'recovery'"
      key="recovery"
      class="popup__content"
    >
      <div class="popup__head">
        <div class="sub-head-h2 black">Восстановление пароля</div>
        <div class="popup__sub-title">
          Для восстановления пароля введите номер телефона, который указывали
          при регистрации. <br />Нажмите кнопку "Отправить пароль".
        </div>
      </div>
      <div class="popup__form">
        <div class="popup__form-input">
          Ваш номер телефона
          <input
            class="input"
            type="text"
            placeholder="Введите номер телефона"
          />
        </div>
      </div>
      <div class="popup__footer">
        <mainBtnVue :className="'green'" :msg="'Отправить пароль'"></mainBtnVue>
      </div>
      <a class="close-popup" @click="$emit('closePopup')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
            fill="#181818"
          />
        </svg>
      </a>
    </div>
    <div v-else-if="message == 'callMe'" key="callMe" class="popup__content">
      <div class="popup__head">
        <div class="sub-head-h2 black">Обратный звонок</div>
      </div>
      <div class="popup__form">
        <div class="popup__form-input">
          Имя
          <input class="input" type="text" placeholder="Введите имя" />
        </div>
        <div class="popup__form-input">
          Телефон
          <input
            class="input phone"
            type="text"
            placeholder="Введите телефон"
          />
        </div>
        <div class="popup__form-checkbox">
          <div class="checkbox">
            <input class="input" type="checkbox" id="checkbox" />
            <label for="checkbox"
              >Нажимая кнопку «Перезвоните мне», вы соглашаетесь с
              <a class="politick">политикой конфиденциальности</a> и
              <a class="politick">правилами обработки персональных данных</a
              >.</label
            >
          </div>
          <a href="#">Забыли пароль?</a>
        </div>
      </div>
      <div class="popup__footer">
        <mainBtnVue :className="'green'" :msg="'Перезвоните мне'"></mainBtnVue>
      </div>
      <a class="close-popup" @click="$emit('closePopup')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
            fill="#181818"
          />
        </svg>
      </a>
    </div>
    <div v-else-if="message == 'fastOrder'" key="fastOrder" class="popup__content">
      <div class="popup__head">
        <div class="sub-head-h2 black">Заказ в один клик</div>
      </div>
      <div class="popup__form">
        <div class="popup__form-input">
          Имя
          <input class="input" type="text" placeholder="Введите имя" />
        </div>
        <div class="popup__form-input">
          Телефон
          <input
            class="input phone"
            type="text"
            placeholder="Введите телефон"
          />
        </div>
        <input type="hidden" :value="this.product_name" :id="this.product_id">
      </div>
      <div class="popup__footer">
        <mainBtnVue :className="'green'" :msg="'Заказать часы'"></mainBtnVue>
      </div>
      <a class="close-popup" @click="$emit('closePopup')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M1.29052 16.7108C1.34277 16.7631 1.4048 16.8046 1.47309 16.8329C1.54138 16.8612 1.61457 16.8758 1.68849 16.8758C1.76241 16.8758 1.83561 16.8612 1.9039 16.8329C1.97218 16.8046 2.03422 16.7631 2.08646 16.7108L8.99959 9.79766L15.9155 16.7108C16.0211 16.8163 16.1642 16.8756 16.3135 16.8756C16.4628 16.8756 16.6059 16.8163 16.7115 16.7108C16.817 16.6052 16.8763 16.4621 16.8763 16.3128C16.8763 16.1635 16.817 16.0204 16.7115 15.9148L9.79552 9.00172L16.7086 2.08578C16.8142 1.98023 16.8735 1.83708 16.8735 1.68781C16.8735 1.53855 16.8142 1.39539 16.7086 1.28984C16.6031 1.1843 16.4599 1.125 16.3107 1.125C16.1614 1.125 16.0183 1.1843 15.9127 1.28984L8.99959 8.20578L2.08365 1.29266C1.97604 1.2005 1.83762 1.15235 1.69606 1.15782C1.55449 1.16329 1.4202 1.22197 1.32002 1.32215C1.21984 1.42233 1.16115 1.55662 1.15569 1.69819C1.15022 1.83976 1.19837 1.97817 1.29052 2.08578L8.20365 9.00172L1.29052 15.9177C1.18576 16.023 1.12695 16.1656 1.12695 16.3142C1.12695 16.4628 1.18576 16.6054 1.29052 16.7108Z"
            fill="#181818"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<style scoped>
.popup {
  background: rgba(31, 31, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popup__content {
  background: #fff;
  padding: 54px 72px 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  width: 854px;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
}
.popup__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.popup__sub-title {
  color: #181818;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.popup__sub-title a {
  color: #397e33;
  text-decoration: underline;
}
.popup__form-input {
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.popup__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.popup__form-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  color: #181818;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.popup__form-checkbox a {
  color: #397e33;
  text-decoration: underline;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.invalid{
  color: red;
  font-size: 12px;
}
</style>