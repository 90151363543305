<script>
import HelpChoice from "@/components/ui/HelpChoice.vue";
export default {
  name: "HomeAdvantagesBlock",
  components:{
    // eslint-disable-next-line vue/no-unused-components
    HelpChoice
  },
  data(){
    return{
      choiceModal: false,
    }
  },
  methods: {
    showChoiceModal(){
      this.choiceModal = true;
      }
  },
};
</script>

<template>
  <div class="home-advantages-block">
    <div class="container sector">
      <div class="home-advantages__content">
        <div class="home-advantages__item">
          <a href="#" class="cursor"><img src="../../assets/img/1.svg" alt="" /></a>

          <div class="home-advantages__text">
            <h3>
              Акции для постоянных <br />
              клиентов в дни праздников
            </h3>
          </div>
        </div>
        <div class="home-advantages__item">
          <img @click="showChoiceModal" class="cursor" src="../../assets/img/2.svg" alt="" />

          <div class="home-advantages__text">
            <h3>Помощь в выборе часов</h3>
          </div>
        </div>
        <div class="home-advantages__item">
          <a href="/delivery" class="cursor"><img src="../../assets/img/3-1.svg" alt="" /></a>

          <div class="home-advantages__text">
            <h3>
              Бесплатная доставка наручных часов <br />
              по всей России
            </h3>
          </div>
        </div>
        <div class="home-advantages__item">
          <a href="https://reviews.yandex.ru/ugcpub/object/shop/bibliotekachasov.ru?lr=120551&text=site%3Abibliotekachasov.ru&utm_source=share_button" class="">
            <img src="../../assets/img/4.svg" alt="" />
          </a>

          <div class="home-advantages__text">
            <h3>
              Хорошее место 5,0 <br />
              Выбор пользователей Яндекса
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <HelpChoice v-if="choiceModal" v-on:closePopup="choiceModal = false"></HelpChoice>
</template>

<style scoped>
.home-advantages__content {
  display: flex;
  justify-content: center;
  gap: 49px;
}
.home-advantages__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
  width: 386px;
}
.sector {
  margin-bottom: 80px;
}
.home-advantages__text h3 {
  color: #1f1f1f;
  text-align: center;
  font-family: "PT Sans Narrow";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
@media screen and (max-width: 1440px) {
  .home-advantages__item{
    width: 208px;
  }
}
@media screen and (max-width: 1028px) {
  .home-advantages__content{
    flex-wrap: wrap;
    gap: 96px;
  }
  .home-advantages__text h3{
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .home-advantages__text h3{
    font-size: 16px;
  }
  .home-advantages__item{
    gap: 10px;
    width: 173px;
    max-width: calc(50% - 27px);
  }
  .home-advantages__content{
    flex-wrap: wrap;
    gap: 15px;
  }
}
</style>